import {
	SELECT_BUILDING,
	SELECT_EARRING,
	SELECT_PEN,
} from "../actions/actionTypes";

const INITIAL_STATE = {
	building: null,
	selectedPen: null,
};

export default function buildingsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
	case SELECT_BUILDING:
		return {
			...state,
			building: action.payload.building,
		};

	case SELECT_PEN:
		return {
			...state,
			selectedPen: action.payload.pen,
		};
	case SELECT_EARRING:
		return {
			...state,
			selectedEarring: action.payload.earring,
		};

	default:
		return state;
	}
}
