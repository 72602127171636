import React from "react";
import {FormattedMessage} from "react-intl";
import flag_pl from './../../resources/flag_pl.svg'
import flag_en from './../../resources/flag_uk.svg'

class ChangeLanguageView extends React.Component {
    countries = {
        pl: {"language": "language.pl", "image": flag_pl},
        en: {"language": "language.en", "image": flag_en},
        short_name: ["pl", "en"]
    }

    constructor(props) {
        super(props);
        this.state = {click: false}
    }

    getCountry(language) {
        let country = this.countries[language];
        let image = country.image;
        return (<div>
            <FormattedMessage id={country.language}/>
            <img src={image} alt={country.language} />
        </div>)
    }

    onClick(language) {
        if (language !== this.props.language)
            this.props.callBackLanguage(language);
        this.setState({click: !this.state.click});
    }

    render() {
        return (
            <div className="drop-down-sz btn-group">
                <div
                    className={this.state.click ? "show_drop_menu change_language dropdown" : "change_language dropdown"}>
                    <button type="button" className="btn btn-primary dropdown-toggle" onClick={() => {
                        this.setState({click: !this.state.click})
                    }} data-toggle="dropdown">
                        {this.getCountry(this.props.language)}
                    </button>
                    <div className={"dropdown-menu"}>
                        {this.countries.short_name.map((item, index) => 

                            <div key={index} onClick={() => {
                                this.onClick(item)
                            }} 
                            className={'div_details'}>
                                <FormattedMessage className="dropdown-item" id={this.countries[item].language}/>
                                <img src={this.countries[item].image} alt={this.countries[item].language}/>
                            </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeLanguageView