import React from "react";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl, defineMessages} from "react-intl";

import {useDispatch} from "react-redux";
import {selectBuilding} from "../../actions/buildings";

import Card from "../common/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

function BuildingCard(props) {
	const dispatch = useDispatch();

	function handleNavigateToBuilding() {
		props.history.push("/menu/pen");
		dispatch(selectBuilding({id: props.id, name: props.title}));
	}

	const formatMessage = props.intl.formatMessage;

	const messages = defineMessages({
		details: {
			id: "details.details_tooltip",
		},
	});

	return (
		<Card
			title={props.title}
			label={`id: ${props.id}`}
			cardActions={(
				<Tooltip title={formatMessage(messages.details)} >
					<Button onClick={handleNavigateToBuilding} color='primary' variant='contained'>
						<FormattedMessage id={"details_card.go_to"}/>
					</Button>
				</Tooltip>
			)}
		>
			<div className="card-body">
				<div className={"div_subtitle"}>
					<img className={"alarm_img"} src={require("./../../resources/pen.svg")} alt='alarm'/>
					<p className="card-text"><FormattedMessage id={"details_card.pen"}/> : {props.details[0]}
					</p>
				</div>
				<div className={"div_subtitle"}>
					<img className={"alarm_img"}
						alt='alarm'
						src={props.details[1] === 0 ? require("./../../resources/pig_ok.svg") : require("./../../resources/pig_bad.svg")}/>
					<p className="card-text"><FormattedMessage id={"details.alerts"}/>: {props.details[1]}</p>
				</div>
			</div>
		</Card>);
}

export default compose(
	injectIntl,
	withRouter,
)(BuildingCard);
