import BuildingModel from "../BuildingModel";

class BuildingParser {
	parser(data) {
		const buildingsArray = [];
		for (let x = 0; x < data.length; x++) {
			const company = data[x];
			const buildings = company.data;
			for (let y = 0; y < buildings.length; y++) {
				const modelBuilding = new BuildingModel({
					building_name: buildings[y].name,
					counter_pen: buildings[y].counter_pen,
					counter_alarm: buildings[y].counter_alarm,
					id: buildings[y].id,
				});
				buildingsArray.push(modelBuilding);
			}
		}
		return buildingsArray;
	}
}

export default BuildingParser;
