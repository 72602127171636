import Grid from "@material-ui/core/Grid/Grid";
import React from "react";
import {Bar} from "react-chartjs-2";
import {injectIntl} from "react-intl";
import {compose} from "recompose";
import { useChartFeeding } from "../../hooks/patient/useChartFeeding";

function ChartFeeding(props) {
    const {
        graphData,
        messages,
        formatMessage,
      } = useChartFeeding(props);
    return (
        <div>
            <Grid container spacing={6} justify='center' className='settings-container'>
                <Bar data={{datasets: [ {
                    label: formatMessage(messages.ambient_temp),
                    data: graphData[0],
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(0, 0, 200,1.0)',
                    type: 'line',
                        yAxisID: 'B',


                },{
                        label: formatMessage(messages.feeding_time),
                        data: graphData[1],
                        backgroundColor: 'rgba(0, 200, 0,1.0)',
                        yAxisID: 'A',
                    }],
                    labels: graphData[2]}}
                     options= {{
                         scales: {
                         yAxes: [{
                         id: 'A',
                         type: 'linear',
                         position: 'left',
                     }, {
                         id: 'B',
                         type: 'linear',
                         position: 'right',

                     }]
                         }
                     }}
                />
            </Grid>
        </div>
    )
}

export default compose(
  injectIntl,
)(ChartFeeding);