import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    '@global': {
        '*': {
            fontFamily: 'Raleway, sans-serif',
        },
    '.MuiTooltip-tooltip': {
        fontFamily: 'Raleway, sans-serif',
    },},
    formRow: {
        display: 'flex-start',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
        marginLeft: '19px'
    },
    navbarItem: {
      position: 'relative',
      boxShadow: '2px 2px 4px #00000033',
      backgroundColor: '#fff',
      width: 'auto',
      height: '40px',
      padding: '5px 15px !important',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '12px !important',
      color: '#574F4F',
      fontWeight: 'bold',
      textAlign: 'center',
    }
}));