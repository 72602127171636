import React from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import PersonalDataView from "./PersonalData";
import PenView from "./PenView";
import EarringStats from "../earring/EarringStats"
import DeviceView from "../statistics/DeviceStatistics";
import SettingsView from "./Settings";
import {connect} from "react-redux";

import Sidebar from "../common/Sidebar";


import "../../style/sass/menu.scss";
import "../../style/sass/card.scss";
import Patient from "../statistics/Patient";
import SettingsDevice from "./SettingsDevice";

class MenuView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pen_id: null,
		};
	}

	render() {
		if (!this.props.isLoggedIn) {
			this.props.history.push("/login");
		}

		return (
			<Router>
				<Route render={({location, history}) => (
					<div className={"menu-div"}>
						<Sidebar/>
						<div className={"div-menu-class"}>
							<Route exact path="/menu/" render={() =>
								<Redirect to="/menu/user"/>}/>
							<Route exact path="/menu/earring/statistics" component={EarringStats}/>
							<Route exact path={"/menu/pen"} component={PenView}/>
							<Route exact path={"/menu/device"} component={DeviceView}/>
							<Route path="/menu/user" exact component={PersonalDataView}/>
							<Route path="/menu/settings/device" exact component={SettingsDevice}/>
							<Route path="/menu/settings" exact component={(props) => <SettingsView
								callBack={this.props.callBack}/>}/>
							<Route path="/menu/patient" exact component={Patient}/>
						</div>
					</div>
				)}/>
			</Router>
		);
	}
}

const mapStateToProps = ({auth}) => ({
	isLoggedIn: auth.loggedIn,
});

export default connect(mapStateToProps)(MenuView);
