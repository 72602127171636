import {combineReducers} from "redux";

import common from "./common";
import buildings from "./buildings";
import auth from "./auth";

const reducers = combineReducers({
	common,
	buildings,
	auth,
});

export default reducers;
