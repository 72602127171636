import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    '@global': {
        '*': {
            fontFamily: 'Raleway, sans-serif',
        },
    '.MuiTable-root': {
        fontFamily: 'Raleway, sans-serif',
      },
    '.MuiTableCell-root': {
    fontFamily: 'Raleway, sans-serif',
    },
    '.MuiIconButton-root': {
        fontFamily: 'Raleway, sans-serif',
      },
    '.MuiButton-root': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiInputBase-root': {
        fontFamily: 'Raleway, sans-serif',
    }},
    table: {
      minWidth: 650,
    },
    tableContainer: {
      borderRadius: 15,
      margin: '10px 10px',
      maxWidth: 'calc(100% - 20px)',
      boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.1)',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
        backgroundColor: '#224f52', 
        color: 'white', 
        padding: '16px 24px',
      },
    firstRow: {
        backgroundColor: '#00272a', 
        color: 'white',
        '& > *': {
          color: 'white',
        },
      },
    tableRow: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#fafafa', 
        },
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      },
      tableCell: {
        padding: '16px 24px', 
      },
      checkIcon: {
        color: 'green',
  
      },
  });
