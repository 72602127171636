import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../style/css/calendar.css'
import { useCalendar } from "../../hooks/earring/useCalendar";

function DayCalendar(props) {

    const {
        currentDate,
        initialMonth,
        lastDayOfMonth,
        tileClassName,
    } = useCalendar(props);

    return (
    <Calendar
        activeStartDate={currentDate}
        tileClassName={tileClassName}
        minDate={initialMonth}
        maxDate={lastDayOfMonth}
        prev2Label={null}
        next2Label={null}
        prevLabel={null}
        nextLabel={null}
    />
    );

}
export default DayCalendar;

