export const URL = () => {
    switch (process.env.REACT_APP_STAGE) {
        case "local":
            return "http://localhost:8000/api";
        case "prod":
            return "https://api.thermoeye.pl/api";
        case "dev":
            return "http://54.36.174.21:25000/api";
        default:
            break;
    }
};
export const SIGN_IN = "%s/user/login/";
export const SIGN_OUT = "%s/user/logout/";
export const GET_USERS_LIST = "%s/user/?from=%s&to=%s";
export const GET_USERS = "%s/user/";
export const USER_REQUEST_DETAILS = "%s/user/%s/";
export const POST_USER = "%s/user/";
export const ROLE_REQUEST = "%s/role/";
export const ROLE_DELETE = "%s/role/%s/";

export const ADDRESS_REQUEST = "%s/address/";
export const GET_COUNTRY = "%s/address/country/";
export const GET_CITY = "%s/address/city/";
export const ADDRESS_DETAILS = "%/address/%s/";

export const COMPANY_REQUEST = "%s/company/";
export const GET_COMPANY_USER = "%s/company/users/";
export const POST_COMPANY_USER = "%s/company/add/users/";
export const COMPANY_DETAIL = "%s/company/%s/";
export const PUT_COMPANY_ADDRESS = "%s/company/add_address/%s/";
export const PUT_ADD_USER = "%s/company/add_user/%s/";

export const ALARM_REQUEST = "%s/building/alarm/";
export const ALARM_DETAILS = "%s/building/alarm/%s/";
export const ALARM_TYPE_REQUEST = "%s/building/alarm/type/";

export const BUILDING_REQUEST = "%s/building/";
export const DEVICE_REQUEST = "%s/device/";
export const DEVICE_DETAILS = "%s/device/%s/";
export const DEVICE_INFO_REQUEST = "%s/device/info/";
export const DEVICE_INFO_DETAILS = "%s/device/info/%s/";
export const DEVICE_ACCESS_REQUEST = "%s/device/access/";
export const DEVICE_ACCESS_DETAILS = "%s/device/access/%s/";
export const DEVICE_INSTALL_TYPE_REQUEST = "%s/device/install_type/";
export const DEVICE_NETWORK_REQUEST = "%s/device/network/";
export const DEVICE_NETWORK_DETAILS = "%s/device/network/%s/";

export const PEN_REQUEST = "%s/building/pen/";
export const PEN_REQUEST_BUILDING_DETAILS = "%s/building/%s/pen/sick/";
export const PEN_REQUEST_FIX_ALARM = "%s/building/pen/%s/sick/";
export const PEN_REQUEST_DEVICES = "/building/pen/device/?pen_id=";
export const PEN_DETAILS = "%s/building/pen/%s/";
export const PEN_ANIMAL_TYPE_REQUEST = "%s/building/pen/animal_type/";
export const PEN_TYPE_REQUEST = "%s/building/pen/type/";

export const SENSOR_REQUEST = "%s/building/sensor/";
export const SENSOR_DETAILS = "%s/building/sensor/%s/";

export const ANIMAL = "%s/animal/";
export const ANIMAL_TEMP = "%s/v2/animal?gte=%s&lte=%s";
export const ANIMAL_UPDATE = "%s/animal/%s/";
export const EARRINGS = "%s/earring";
export const EARRING_STATS = "%s/earring/%s/statistic?gte=%s&lte=%s";
export const ANIMAL_DATA_TABLE = "%s/thermo/table/?id_animal=%s&gte=%s&lte=%s";
export const SICK_ANIMAL_DATA_TABLE = "%s/thermo/sick_animal/?gte=%s&lte=%s";
export const FEEDING_ANIMAL_DATA_TABLE =
    "%s/thermo/feeding_animal/?gte=%s&lte=%s";
export const FEEDING_ANIMAL_DATA_GRAPH =
    "%s/thermo/feeding_ambient_animal/?gte=%s&lte=%s";
export const EXTERNAL_SENSOR_DATA =
    "%s/thermo/external_sensor/?localization=%s&gte_timestamp=%s&lte_timestamp=%s";

export const EXISTING_INDICES = "/thermo/existing_indices/?device=";
export const THERMO_GRAPH = (device, gte, lte, feature_name) =>
    `/thermo/graph/?device=${device}&gte=${gte}&lte=${lte}&feature_name=${feature_name}`;
export const HISTORY_DATA = (device, gte, date) =>
    `/thermo/history_request/?device=${device}&gte=${gte}&date=${date}`;
export const THERMO_LOGS = (device, gte, lte, typeRequest) =>
    `/thermo/logs/?device=${device}&gte=${gte}&lte=${lte}&typeRequest=${typeRequest}`;
export const HISTORY_DATA_TO_GRAPH = "/thermo/history_data_to_graph/";

export const SETTINGS_TOASTER = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
};
