import React, {useState} from "react"
import { defineMessages} from "react-intl";
import { useStyles } from '../../style/componentsStyling/MonthYearPicker.styles'

export const useMonthYearPicker = (props) => {
    const classes = useStyles();
    const [month, setMonth] = useState(props.month !== undefined ? props.month : new Date().getMonth());
    const [year, setYear] = useState(props.year !== undefined ? props.year : new Date().getFullYear());
    const [years, setYears] = useState([]);
    const formatMessage = props.intl.formatMessage;

    const messages = defineMessages({
      months: {
        id: "months",
      },
      month: {
        id: "month",
      },
      year: {
        id: "year",
      },
    });
    const months = formatMessage(messages.months)

    const handleMonthChange = (event) => {
        const monthIndex = months.indexOf(event.target.value);
        setMonth(monthIndex);
        props.onDateChange(year, monthIndex);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        props.onDateChange(event.target.value, month);
    };

    React.useEffect(() => {
        const generatedYears = Array.from({ length: 7 }, (_, i) => year + 2 - i);
        setYears(generatedYears);
    }, [year]);

    return {
        month,
        months,
        year,
        years,
        handleMonthChange,
        handleYearChange,
        messages,
        formatMessage,
        classes
    };
}