import React from 'react';
import { Modal, Box, Typography } from '@material-ui/core';
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
  } from '@material-ui/core';
  import {injectIntl} from "react-intl";
  import {compose} from "recompose";
  import { useDetailsModal } from '../../hooks/earring/useDetailsModal';
  import {getGte, getLte} from "../../helpers/dateTimeHelpers"

function DetailsModal (props) {
  const {
    data,
    setData,
    goToEarringStats,
    isLoading,
    messages,
    formatMessage,
    classes
} = useDetailsModal(props);


  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        setData([]); 
        props.handleClose();
      }}
      aria-labelledby="animal-details-title"
      aria-describedby="animal-details-description"
    >
      <Box className={classes.modal}>
        <Typography variant="h6" id="animal-details-title" className={classes.modalTitle}>{formatMessage(messages.details)}</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" id="animal-details-title" className={classes.animalId}>
          {formatMessage(messages.animal_id)}: {props.animal}
          </Typography>
          <Button variant="contained" color="primary" onClick={goToEarringStats}>
          {formatMessage(messages.earring_stats)}
          </Button>
        </Box>
        <Typography variant="h6" className={classes.tableHeading}>
        {formatMessage(messages.measurements)}
        </Typography>
        {!isLoading && data.length > 0 && (
        <Box className={classes.modalContent}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
		    <TableHead>
                <TableRow>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.date_start)}</TableCell>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.date_end)}</TableCell>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.temp)}</TableCell>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.ambient_temp)}</TableCell>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.higher_temp)}</TableCell>
                    <TableCell align="center" className={classes.tableHeaderCell}>{formatMessage(messages.device)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((element, index) =>
                    <TableRow key={index} id={index}>
                        <TableCell
                            align="center">{getGte(element.start_time, element.end_time)}</TableCell>
                        <TableCell
                            align="center">{getLte(element.start_time, element.end_time)}</TableCell>
                        <TableCell align="center">{element.temp}</TableCell>
                        <TableCell align="center">{element.ambient_temp}</TableCell>
                        <TableCell align="center">{element.sick ? formatMessage(messages.yes) : formatMessage(messages.no)}</TableCell>
                        <TableCell align="center">{element.device_name}</TableCell>
                    </TableRow>,
                )}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      )}
      </Box>
      
    </Modal>
  );
};


export default compose(
  injectIntl,
)(DetailsModal);