import {useState} from "react";
import {defineMessages} from "react-intl";
import { useStyles } from '../../style/componentsStyling/StatsTable.styles'


export const useStatsTable = (props) => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAnimal, setSelectedAnimal] = useState('');

    const formatMessage = props.intl.formatMessage;

    const messages = defineMessages({
      ambient_temperature: {
        id: "patient.ambient",
      },
      total_attempts: {
        id: "patient.total_attempts",
      },
      animal_id: {
        id: "patient.id",
      },
      details: {
        id: "patient.details",
      },
    });

    const handleOpenModal = (animal) => {
        setSelectedAnimal(animal);
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

    console.log(props.feedingData)
    console.log(props.feedingData.data)


    const getTemperatureById = (id) => {
        const tempObj = props.tempData.find(temp => temp.rf_id === id);
        return tempObj ? tempObj.avg_ambient_temp : 'N/A';
    };

    return {
        getTemperatureById,
        handleOpenModal,
        handleCloseModal,
        selectedAnimal,
        isModalOpen,
        messages,
        formatMessage,
        classes
    };
}