import React from "react";
import clsx from "clsx";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../loader";
import NavBar from "../common/NavBar";
import Card from "../common/Card";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Chart from "./Chart";

import { get } from "../../net/http_client";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import { sessionExpired } from "../../actions/auth";

import "react-datepicker/dist/react-datepicker.css";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import {
    EXISTING_INDICES,
    PEN_REQUEST_DEVICES,
    SETTINGS_TOASTER,
    THERMO_GRAPH,
    THERMO_LOGS,
} from "../../config";
import TemperatureSlider from "./TemperatureSlider";
import { createStyles } from "@material-ui/core/styles";
import { IconButton, withStyles } from "@material-ui/core";
import format from "date-fns/format";

class DeviceView extends React.Component {
    logs = false;

    constructor(props) {
        super(props);
        this.state = {
            pk: 0,
            startDate: new Date(Date.now() - 86400000),
            endDate: new Date(),
            sendRequest: false,
            dateList: [],
            dateListDaysTimeStamp: [],
            logs: false,
            rangeFrom: 15,
            rangeTo: 40,
            range: [15, 40],
            loading: false,
            isCurrentDeviceEnabled: false,
            graph_data: null,
            device: [],
        };

        this.graphRef = React.createRef();
    }

    setMouseHandler = () => {
        if (this.graphRef) {
            this.graphRef.chartInstance.$zoom._mouseDownHandler = (event) => {
                if (event.which === 3) {
                    event.preventDefault();
                    return (this.graphRef.chartInstance.$zoom._dragZoomStart = event);
                }

                return (this.graphRef.chartInstance.$zoom._dragZoomStart = null);
            };
        }
    };

    generateGraphData = (data) => {
        let ambient = [];
        let sensor_1 = [];
        let sensor_2 = [];

        data.forEach(function(el) {
            const values = el.features;

            ambient.push({
                y: values.ambient_temp
                    ? Number(values.ambient_temp).toFixed(2)
                    : 0,
                t: moment(el.timestamp, "YYYY-MM-DD HH:mm"),
            });
            sensor_1.push({
                y: values.median_0 ? Number(values.median_0).toFixed(2) : 0,
                t: moment(el.timestamp, "YYYY-MM-DD HH:mm"),
            });
            sensor_2.push({
                y: values.median_1 ? Number(values.median_1).toFixed(2) : 0,
                t: moment(el.timestamp, "YYYY-MM-DD HH:mm"),
            });
        });

        this.setState({
            graph_data: {
                datasets: [
                    {
                        label: "ambient",
                        data: ambient,
                        backgroundColor: "transparent",
                        borderColor: "rgba(46, 204, 113,1)",
                        pointRadius: 1,
                        borderWidth: 1,
                    },
                    {
                        label: `sensor_1`,
                        data: sensor_1,
                        backgroundColor: "transparent",
                        borderColor: "rgba(52, 152, 219,1)",
                        borderWidth: 1,
                        pointRadius: 1,
                    },
                    {
                        label: `sensor_2`,
                        data: sensor_2,
                        backgroundColor: "transparent",
                        borderColor: "rgba(231, 76, 60,1)",
                        borderWidth: 1,
                        pointRadius: 1,
                    },
                ],
            },
        });

        if (this.graphRef.chartInstance)
            this.graphRef.chartInstance.resetZoom();
    };

    fetchDataWithMaping(gte, lte) {
        this.setState({ loading: true });
        const device = this.state.device.find((x) => x.id === this.state.pk)
        if(!device) alert("Nie znaleziono urządzenia")
        const mac_address_id = device.device_network_info.mac_address_id;
        get(THERMO_GRAPH(mac_address_id, gte, lte, "median"))
            .then((res) => {
                this.setState({ loading: false });
                this.generateGraphData(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
        if (this.state.logs) {
            this.downloadLogs(mac_address_id, gte, lte);
        }
    }

    downloadLogs = (device, gte, lte) => {
        this.setState({ progressBar: true });
        get(THERMO_LOGS(device, gte, lte, "alert"))
            .then((res) => {
                this.setState({ progressBar: false });

                if (res.data.logs.length) {
                    let logs = [];
                    const values = res.data.logs;
                    values.forEach(
                        (item) =>
                            item["log_level"] &&
                            logs.push({
                                y: 20,
                                message: `${item.message}`,
                                t: moment(
                                    item["@timestamp"],
                                    "YYYY-MM-DD HH:mm"
                                ),
                            })
                    );
                    const logs_dataset = {
                        label: `logs`,
                        data: logs,
                        backgroundColor: "rgba(254, 211, 48, .2)",
                        borderColor: "rgba(254, 211, 48, 1)",
                        borderWidth: 1,
                        pointRadius: 3,
                        showLine: false,
                    };
                    this.setState((prevState) => ({
                        ...prevState,
                        graph_data: {
                            ...prevState.graph_data,
                            datasets: [
                                ...prevState.graph_data.datasets,
                                logs_dataset,
                            ],
                        },
                    }));
                }
            })
            .catch((error) => {
                this.setState({ progressBar: false });
                console.error(error);
            });
    };
    fetchData = () => {
        this.setMouseHandler();
        this.setState({
            dateDiff: moment(this.state.startDate).diff(
                this.state.endDate,
                "days"
            ),
        });

        let gte = moment(this.state.startDate).format("YYYY-MM-DD HH:mm:00");
        let lte = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:00");

        this.fetchDataWithMaping(gte, lte);
    };

    handleZoomComplete = ({ chart }) => {
        setTimeout(() => {
            const gte = moment(chart.scales["x-axis-0"].min).format(
                "YYYY-MM-DD HH:MM:00"
            );
            const lte = moment(chart.scales["x-axis-0"].max).format(
                "YYYY-MM-DD HH:MM:00"
            );

            this.setState({ dateDiff: moment(gte).diff(lte, "days") });

            this.fetchDataWithMaping(gte, lte);
        }, 100);
    };

    componentDidMount() {
        if (this.props.building && this.props.pen) {
            this.setState({ loading: true });
            get(PEN_REQUEST_DEVICES + this.props.pen.id).then((response) => {
                switch (response.status) {
                    case 401:
                        sessionStorage.removeItem("token");
                        this.props.sessionExpired();
                        toast.info("Session expired");
                        break;
                    case 200:
                        this.setState({
                            device: response.data,
                            pk: response.data.length ? response.data[0].id : 0,
                            isCurrentDeviceEnabled:
                                response.data[0].device_info.is_enabled,
                        });
                        this.downloadDate(
                            this.state.device.length
                                ? this.state.device[0].device_network_info
                                      .mac_address_id
                                : null
                        );
                        this.setState({ loading: false });
                        break;

                    default:
                        break;
                }
            });
        }
    }

    handleChangeDevice = (mac, id, is_enabled) => {
        this.downloadDate(mac);
        this.setState({ pk: id, isCurrentDeviceEnabled: is_enabled });
    };

    handleChangeRangeFrom = (ev, value) => {
        if (value < this.state.rangeTo) {
            this.setState({ rangeFrom: parseInt(value) });
        }
    };

    handleChangeRangeTo = (ev, value) => {
        if (value > this.state.rangeFrom) {
            this.setState({ rangeTo: parseInt(value) });
        }
    };

    changeLogs() {
        this.logs = !this.logs;
    }

    saveSettings() {
        this.setState({
            logs: this.logs,
            range: [this.state.rangeFrom, this.state.rangeTo],
        });
        toast.info("Save Settings", SETTINGS_TOASTER);

        if (!this.state.logs) {
            const device = this.state.device.find((x) => x.id === this.state.pk)
            if(!device) alert("Nie znaleziono urządzenia")
            const mac_address_id = device.device_network_info.mac_address_id;

            let gte = moment(this.state.startDate).format(
                "YYYY-MM-DD HH:mm:00"
            );
            let lte = moment(this.state.endDate).format("YYYY-MM-DD HH:mm:00");

            this.downloadLogs(mac_address_id, gte, lte);
        }
    }

    getDeviceName() {
        if (this.state.device.length !== 0) {
            return this.state.device.map((item, index) => {
                let name = item.device_info.name;
                return (
                    <div
                        className={"drop-down-details"}
                        onClick={() =>
                            this.handleChangeDevice(
                                item.device_network_info.mac_address_id,
                                index,
                                item.device_info.is_enabled
                            )
                        }
                    >
                        {name != ""
                            ? name
                            : this.props.building.name +
                              "_" +
                              this.props.pen.id +
                              "_" +
                              item.id}
                    </div>
                );
            });
        }
    }

    getDropDownName() {
        if (this.state.device.length !== 0) {
            for (let x in this.state.device) {
                if (this.state.device[x].id === this.state.pk) {
                    if (this.state.device[x].device_info.name != "")
                        return this.state.device[x].device_info.name;
                    return (
                        this.props.building.name +
                        "_" +
                        this.props.pen.id +
                        "_" +
                        this.state.device[x].id
                    );
                }
            }
        }
    }

    downloadDate(device) {
        this.setState({ loading: true });

        get(EXISTING_INDICES + device).then((res) => {
            let date = [];
            let dateDays = [];
            if (res.data.dates && res.data.dates.length) {
                date = this.arrayStringToArrayDate(res.data.dates);
                for (let x = 0; x < date.length; x++) {
                    dateDays.push(
                        parseInt(date[x].getTime() / (1000 * 3600 * 24))
                    );
                }
            }

            this.setState({
                dateList: date,
                loading: false,
                dateListDaysTimeStamp: dateDays,
            });
        });
    }

    arrayStringToArrayDate(stringArray) {
        let dateArray = [];
        stringArray = stringArray.sort();
        for (let x in stringArray) {
            let date = new Date(stringArray[x]);
            date.setHours(0, 0, 0, 0);
            dateArray.push(date);
        }
        let date = dateArray[dateArray.length - 1];
        return dateArray;
    }

    handleStartDate = (e) => {
        this.setState({ startDate: e });
    };
    handleEndDate = (e) => {
        this.setState({ endDate: e });
    };

    makeJSDateObject(date) {
        if (date instanceof Date) {
            date.setHours(0, 0, 0, 0);
            return date;
        }
        if (date instanceof moment) {
            date = date.clone().toDate();
            date.setHours(0, 0, 0, 0);
            return date;
        }

        throw new Error(
            "Cannot properly parse argument passed to cloneCrossUtils"
        );
    }

    renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth, startFlags) {
        const { classes } = this.props;
        let startDate = null;
        let endDate = null;
        let dayIsBetween = false;
        let dateClone = this.makeJSDateObject(date);

        let day = this.state.dateListDaysTimeStamp.includes(
            parseInt(dateClone.getTime() / (1000 * 3600 * 24))
        );
        if (startFlags) {
            startDate = this.makeJSDateObject(selectedDate);
            endDate = this.makeJSDateObject(this.state.endDate);
        } else {
            startDate = this.makeJSDateObject(this.state.startDate);
            endDate = this.makeJSDateObject(selectedDate);
        }

        const start = startDate;
        const end = endDate;
        if (start.getTime() <= end.getTime()) {
            dayIsBetween = isWithinInterval(dateClone, { start, end });
        }
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);
        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]:
                !dayInCurrentMonth && dayIsBetween,
            [classes.index]: day && dayInCurrentMonth && !dayIsBetween,
        });
        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    }

    renderDatePicker = (timeStart) => {
        const { classes } = this.props;
        return (
            <>
                <p className={classes.label}>
                    {this.props.intl.formatMessage({
                        id: "chart.startDate_tooltip",
                    })}{" "}
                    :
                </p>
                <KeyboardDateTimePicker
                    variant="outlined"
                    ampm={false}
                    value={this.state.startDate}
                    onChange={this.handleStartDate}
                    format="YYYY/MM/DD HH:mm"
                    renderDay={(date, selectedDate, dayInCurrentMonth) =>
                        this.renderWrappedWeekDay(
                            date,
                            selectedDate,
                            dayInCurrentMonth,
                            true
                        )
                    }
                    disableFuture
                />
                <p className={classes.label}>
                    {this.props.intl.formatMessage({
                        id: "chart.endDate_tooltip",
                    })}{" "}
                    :
                </p>
                <KeyboardDateTimePicker
                    variant="outlined"
                    ampm={false}
                    value={this.state.endDate}
                    onChange={this.handleEndDate}
                    renderDay={(date, selectedDate, dayInCurrentMonth) =>
                        this.renderWrappedWeekDay(
                            date,
                            selectedDate,
                            dayInCurrentMonth,
                            false
                        )
                    }
                    format="YYYY/MM/DD HH:mm"
                    disableFuture
                />
            </>
        );
    };

    renderOptions(boundary) {
        let tab = [];
        for (let x = -10; x <= 55; x++) {
            if (x === boundary) {
                tab.push(
                    <option selected value="x">
                        {x}
                    </option>
                );
            } else {
                tab.push(<option>{x}</option>);
            }
        }
        return tab;
    }

    getGraphSettings() {
        return (
            <Card
                title={this.props.intl.formatMessage({
                    id: "device.graph_settings",
                })}
                cardActions={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.saveSettings.bind(this)}
                    >
                        <FormattedMessage id={"device.save"} />
                    </Button>
                }
            >
                <div className="card-body">
                    <TemperatureSlider
                        label={this.props.intl.formatMessage({
                            id: "chart.min_temperature",
                        })}
                        onChange={this.handleChangeRangeFrom}
                        value={this.state.rangeFrom}
                    />
                    <TemperatureSlider
                        label={this.props.intl.formatMessage({
                            id: "chart.max_temperature",
                        })}
                        onChange={this.handleChangeRangeTo}
                        value={this.state.rangeTo}
                    />

                    <div className={"div_subtitle"}>
                        <p className="card-text">
                            <FormattedMessage id={"device.logs"} />
                        </p>
                        <div className="input-group mb-3">
                            <Tooltip
                                title={this.props.intl.formatMessage({
                                    id: "chart.alerts_tooltip",
                                })}
                                placement="bottom-start"
                            >
                                <Checkbox
                                    value={this.logs}
                                    onChange={this.changeLogs.bind(this)}
                                    color="secondary"
                                    id="logs"
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    render() {
        if (!this.props.building || !this.props.pen) {
            return <Redirect to="/menu/user" push />;
        }

        const messages = defineMessages({
            status: {
                id: "device.status_tooltip",
            },
            noDatesWarning: {
                id: "device.no_dates_warning",
            },
        });

        const formatMessage = this.props.intl.formatMessage;
        return (
            <Grid
                container
                spacing={6}
                justify="space-between"
                className="device-statistics-container"
            >
                <Loader visible={this.state.loading} />

                <NavBar
                    locationName={this.props.building.name}
                    penId={this.props.pen.id}
                />

                <Grid item xs={12} sm={7} md={5}>
                    <Card
                        status={formatMessage({
                            id: this.state.isCurrentDeviceEnabled
                                ? "device.is_enabled"
                                : "device.is_disabled",
                        })}
                        statusColor={
                            this.state.isCurrentDeviceEnabled
                                ? "#28a745"
                                : "#dc3545"
                        }
                        statusTooltip={formatMessage(messages.status)}
                        title={formatMessage({ id: "device.card_title" })}
                        cardActions={
                            this.state.dateList.length ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.fetchData}
                                >
                                    <FormattedMessage id={"device.download"} />
                                </Button>
                            ) : null
                        }
                    >
                        <div className="card-body">
                            <div className={"flex-body"}>
                                <div className="input-group mb-3">
                                    <div className="btn-group">
                                        <div
                                            className={
                                                this.state.click
                                                    ? "show_drop_menu drop-down-sz dropdown datapicker-container"
                                                    : "drop-down-sz dropdown datapicker-container"
                                            }
                                        >
                                            <span className="badge badge-primary">
                                                <FormattedMessage
                                                    id={"device.name"}
                                                />
                                            </span>
                                            <button
                                                type="button"
                                                className="btn btn-primary dropdown-toggle"
                                                onClick={() =>
                                                    this.setState(
                                                        (prevState) => ({
                                                            click: !prevState.click,
                                                        })
                                                    )
                                                }
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {this.getDropDownName()}
                                            </button>
                                            <div className={"dropdown-menu"}>
                                                {this.getDeviceName()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="btn-group">
                                        {this.state.dateList.length ? (
                                            <div className=" datapicker-container">
                                                <div className="drop-down-sz dropdown">
                                                    <span className="badge badge-primary">
                                                        <FormattedMessage
                                                            id={"device.date"}
                                                        />
                                                    </span>
                                                </div>
                                                {this.renderDatePicker(false)}
                                            </div>
                                        ) : (
                                            <Tooltip
                                                title={formatMessage(
                                                    messages.noDatesWarning
                                                )}
                                            >
                                                <span class="badge badge-warning warning-label">
                                                    <FormattedMessage id="device.no_date" />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={5} md={5}>
                    {this.getGraphSettings()}
                </Grid>

                <Grid item xs={12}>
                    <div
                        className="graph-container"
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <Chart
                            setRef={(ref) => (this.graphRef = ref)}
                            data={this.state.graph_data}
                            title={this.props.intl.formatMessage({
                                id: "chart.temperatures",
                            })}
                            minYScale={this.state.range[0]}
                            maxYScale={this.state.range[1]}
                            timeUnit={this.state.dateDiff > 2 ? "day" : "hour"}
                            onZoomComplete={this.handleZoomComplete}
                        />
                    </div>
                </Grid>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover={false}
                />
            </Grid>
        );
    }
}

const mapStateToProps = ({ buildings }) => ({
    building: buildings.building,
    pen: buildings.selectedPen,
});

const mapDispatchToProps = (dispatch) => ({
    sessionExpired: () => dispatch(sessionExpired()),
});
const styles = createStyles((theme) => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
    index: {
        background: "#52af77",
    },
    label: {
        marginTop: "10px",
        marginBottom: "0px",
    },
}));
export default compose(
    injectIntl,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DeviceView);
