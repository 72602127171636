import {
	TOGGLE_SIDEBAR,
} from "../actions/actionTypes";

const INITIAL_STATE = {
	sidebarIsOpen: false,
};

export default function commonReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
	case TOGGLE_SIDEBAR:
		return {
			...state,
			sidebarIsOpen: !state.sidebarIsOpen,
		};

	default:
		return state;
	}
}
