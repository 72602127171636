import React from "react"
import { useSelector } from "react-redux";
import {useDispatch} from "react-redux";
import {sessionExpired} from "../../actions/auth";
import { useHistory } from "react-router-dom";
import {request} from "../../net/http_client";
import {EARRING_STATS} from "../../config";
import {defineMessages} from "react-intl";
import { useStyles } from '../../style/componentsStyling/EarringStats.styles'


export const useEarringStats = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const formatMessage = props.intl.formatMessage;

    const messages = defineMessages({
      earring: {
        id: "patient.earring",
      },
    });
  const earring = useSelector((store) => store.buildings.selectedEarring)
  const [dates, setDates] = React.useState([])
  const [month, setMonth] = React.useState(earring ? earring.month : new Date().getMonth());
  const [year, setYear] = React.useState(earring ? earring.year : new Date().getFullYear());
  const [rf_id, setRf_id] = React.useState(earring ? earring.rf_id : '--------------');

 
const classes = useStyles();

  const downloadData = async (selectedYear, selectedMonth) => {
      setYear(selectedYear);
      setMonth(selectedMonth);
      
      const startDate = new Date(selectedYear, selectedMonth, 2).toISOString().slice(0, 10);
      const endDate = new Date(selectedYear, selectedMonth + 1, 2).toISOString().slice(0, 10);
      try {
        const response = await request(EARRING_STATS, "GET", null, rf_id, startDate, endDate);
        if (response.status === 200 && response.data) {
            setDates(response.data);
        } else if (response.status === 401) {
            sessionStorage.removeItem("token");
            dispatch(sessionExpired());
        }
    } catch (error) {
        console.log("Error downloading data", error);
    }

  }

  React.useEffect(() => {
    if ((earring && !earring.rf_id) || month === undefined || year === undefined) {
        history.goBack();
    } else {
        downloadData(year, month);
    }
}, [earring, month, year, history]);


    return {
        dates,
        month,
        year,
        rf_id,
        downloadData,
        messages,
        formatMessage,
        classes
    };

}