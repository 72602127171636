import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles((theme) => ({
	card: {
		boxShadow: "2px 2px 4px 2px #00000033",
		background: "#ffffffbb",
		borderRadius: 10,
		position: "relative",
	},
	cardHeader: {
		width: "100%",
		fontSize: "1.7em",
		fontWeight: "bold",
		display: "flex",
		justifyContent: "space-between",
	},
	cardLabel: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		width: 70,
		fontSize: ".6em",
		height: 30,
		borderRadius: 10,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	statusLabel: {
		color: "#fff",
		width: 70,
		fontSize: ".4em",
		height: 20,
		borderRadius: 10,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	cardFooter: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",
	},
	paper: {
		overflow: "visible",
	},
}));

function CustomCard(props) {
	const classes = useStyles();

	const {
		cardActions,
		children,
		title,
		label,
		status,
		statusColor,
		statusTooltip,
	} = props;

	return (
		<Grow in={true}>
			<Card className={classes.card} classes={{
				root: classes.paper,
			}}>
				<CardContent>
					<div className={classes.cardHeader}>
						<p>{title}</p>
						{label && <span className={classes.cardLabel}>{label}</span>}
						{status &&
                            <Tooltip title={statusTooltip} placement='bottom'>
                            	<span style={{backgroundColor: statusColor || "#224f52"}} className={classes.statusLabel}>{status}</span>
                            </Tooltip>}
					</div>
				</CardContent>
				<div className={classes.cardBody}>
					{children}
				</div>
				<CardActions className={classes.cardFooter}>
					{cardActions}
				</CardActions>
			</Card>
		</Grow>);
}

export default CustomCard;
