import ReactAxiom from "react-axiom/src";

class BuildingModel extends ReactAxiom.Model {
	static defaultState() {
		return {
			id: null,
			building_name: null,
			counter_pen: null,
			counter_alarm: null,
			address: null,
		};
	}
}
export default BuildingModel;
