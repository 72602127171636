// MonthYearPicker.js
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import {injectIntl} from "react-intl";
import {compose} from "recompose";
import { useMonthYearPicker } from '../../hooks/patient/useMonthYearPicker';

const MonthYearPicker = ( props ) => {
    const {
        month,
        months,
        year,
        years,
        handleMonthChange,
        handleYearChange,
        messages,
        formatMessage,
        classes
      } = useMonthYearPicker(props);

    return (
        <>
            <FormControl className={classes.monthFormControl}>
                <InputLabel>{formatMessage(messages.month)}</InputLabel>
                <Select 
                value={months[month]} 
                onChange={handleMonthChange} 
                className={classes.select} 
                MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null, // This prevents the menu from using the select field to position itself
                  }}
                >
                    {months.map((name, index) => (
                        <MenuItem key={index} value={name}>{name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.yearFormControl}>
                <InputLabel>{formatMessage(messages.year)}</InputLabel>
                <Select 
                value={year} 
                onChange={handleYearChange} 
                className={classes.select}
                MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null, // This prevents the menu from using the select field to position itself
                  }}
                >
                    {years.map((y) => (
                        <MenuItem key={y} value={y}>{y}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default compose(
	injectIntl,
)(MonthYearPicker);
