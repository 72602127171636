import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fontFamily: 'Raleway, sans-serif',
    '@global': {
        '*': {
            fontFamily: 'Raleway, sans-serif',
        },
    '.MuiTypography-h5': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-h6': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-body1': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-root': {
        fontFamily: 'Raleway, sans-serif',
    },},
    formRow: {
        display: 'flex-start',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
        marginLeft: '5px'
    },
      paper: {
        overflowX: 'auto',
        padding: theme.spacing(3),
        margin: theme.spacing(2.5),
        minHeight: '400px',
        backgroundColor: 'white', 
        borderRadius: 15, 
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        statTypography: {
            fontSize: '1.2rem', 
            fontWeight: 500,
            color: '#333',
            lineHeight: '1.5',
            textAlign: 'center',
            marginBottom: theme.spacing(1), 
        },
        headingTypography: {
            margin: theme.spacing(1),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
        flexContainer: {
            display: 'flex',
            flexDirection: 'row', 
            flexWrap: 'nowrap', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: theme.spacing(2),
            margin: theme.spacing(2),
            marginTop: '0px'
        },
        textSection: {
            maxWidth: '40%', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            textAlign: 'center',
        },
        noDataPaper: {
            padding: theme.spacing(3),
            marginLeft: '20%',
            margin: 'auto', 
            maxWidth: '60%',
            minHeight: '100px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', 
            backdropFilter: 'blur(3px)', 
            borderRadius: theme.shape.borderRadius, 
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        noDataText: {
            textAlign: 'center',
            fontWeight: 500,
            color: theme.palette.primary.main, 
        },
}));
