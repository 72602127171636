import { createTheme, } from '@material-ui/core/styles';

export const theme = createTheme({
    '.MuiTable-root': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTableCell-root': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiIconButton-root': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-h5': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-h6': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-body1': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-root': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiButton-root': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTooltip-tooltip': {
        fontFamily: 'Raleway, sans-serif',
    },
})