import React from "react";

export const useCalendar = (props) => {
    const initialMonth = new Date(props.year, props.month);
    const lastDayOfMonth = new Date(props.year, props.month + 1, 0);

    const [currentDate, setCurrentDate] = React.useState(initialMonth);

    React.useEffect(() => {
        setCurrentDate(new Date(props.year, props.month));
    }, [props.month, props.year]);

    const tileClassName = ({ date, view }) => {
    if (view === 'month' && Object.keys(props.dates).some(d => new Date(d).toDateString() === date.toDateString())) {
        return 'highlight';
    }
    };

    return {
        currentDate,
        initialMonth,
        lastDayOfMonth,
        setCurrentDate,
        tileClassName,
    };
}