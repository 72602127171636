import React from "react";
import UserSettingsView from "../settings/UserSettings";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {SETTINGS_TOASTER} from "../../config";
import Grid from '@material-ui/core/Grid';

class SettingsView extends React.Component {
    status = {
        Success: 0,
        Error: -1
    };

    callToaster(message, status) {
        switch (status) {
            case this.status.Success:
                toast.success(message, SETTINGS_TOASTER);
                break;
            case this.status.Error:
                toast.error(message, SETTINGS_TOASTER);
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <Grid container spacing={6} justify='center' className='settings-container'>
                <Grid item xs={12} sm={8} md={7}>
                    <div className="card text-center">
                        <UserSettingsView callBack={this.props.callBack} toaster={this.callToaster.bind(this)}
                                          statusToaster={this.status}/>
                    </div>
                    <ToastContainer position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick={false}
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover={false}/>
                </Grid>
            </Grid>
        );
    }

}

export default SettingsView
