import {
	LOGIN,
	LOGOUT,
	SESSION_EXPIRED,
} from "./actionTypes";

export function login(user) {
	return {
		type: LOGIN,
		payload: {
			user,
		},
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export function sessionExpired() {
	return {
		type: SESSION_EXPIRED,
	};
}
