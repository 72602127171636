import React from "react";
import { Tooltip as MaterialTooltip }  from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DayCalendar from "./Calendar";
import DayBarChart from "./BarChart";
import MonthYearPicker from "../statistics/MonthYearPicker";
import {injectIntl} from "react-intl";
import {compose} from "recompose";
import { useEarringStats } from "../../hooks/earring/useEarringStats";


function EarringStats(props) {
  const {
      dates,
      month,
      year,
      rf_id,
      downloadData,
      messages,
      formatMessage,
      classes
    } = useEarringStats(props);

	return (
        <>
        <Grid container spacing={3} className='pens-container' justifyContent='left'>
        <MaterialTooltip title={formatMessage(messages.earring) + ' ' + rf_id}>
					<div className={classes.navbarItem}>
          {formatMessage(messages.earring)} {rf_id}
					</div>
        </MaterialTooltip>
        </Grid>
        <div className={classes.formRow}>
                <MonthYearPicker onDateChange={downloadData} month={month} year={year}/>
        </div>
        <Grid container justifyContent="space-between" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <div className="react-calendar-wrapper">
            <DayCalendar dates={dates} month={month} year={year}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <div className="barchart-wrapper">
            <DayBarChart dates={dates} month={month} year={year} intl={props.intl} />
          </div>
        </Grid>
      </Grid>
        </>
    );
}

export default compose(
  injectIntl,
)(EarringStats);