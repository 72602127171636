import {useState, useEffect} from "react";
import { ANIMAL_DATA_TABLE, SETTINGS_TOASTER } from '../../config';
import { request } from '../../net/http_client';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { defineMessages} from "react-intl";
import { sessionExpired } from '../../actions/auth';
import { selectEarring } from "../../actions/buildings";
import { useStyles } from '../../style/componentsStyling/DetailsModal.styles'

export const useDetailsModal = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const formatMessage = props.intl.formatMessage;

    const classes = useStyles();

    const messages = defineMessages({
      yes: {
        id: "yes",
      },
      no: {
        id: "no",
      },
      date_start: {
        id: "patient.date_start",
      },
      date_end: {
        id: "patient.date_end",
      },
      temp: {
        id: "patient.temp",
      },
      ambient_temp: {
        id: "patient.ambient",
      },
      animal_id: {
        id: "patient.id",
      },
      details: {
        id: "patient.details",
      },
      earring_stats: {
        id: "patient.earring_stats",
      },
      measurements: {
        id: "patient.measurements",
      },
      device: {
        id: "navbar.device",
      },
      higher_temp: {
        id: "patient.higher_temp",
      },
      data_download_error: {
        id: "patient.data_download_error",
      }
    });

  const goToEarringStats = () => {
    history.push('/menu/earring/statistics');
    dispatch(selectEarring({rf_id: props.animal, year: props.year, month: props.month}));
  };

  const downloadData = () => {
    setIsLoading(true);
    props.setProgressBar(true);
    const startDate = new Date(props.year, props.month, 2).toISOString().slice(0, 10);
    const endDate = new Date(props.year, props.month + 1, 2).toISOString().slice(0, 10);
    request(ANIMAL_DATA_TABLE, "GET", [], props.animal, startDate, endDate).then((response) => {
        if (response.status === 200) {
            setData(response.data);
        } else if (response.status === 401) {
            sessionStorage.removeItem("token");
            dispatch(sessionExpired());
        } else {
            toast.info(messages.data_download_error, SETTINGS_TOASTER);
            setData([]);
        }
        props.setProgressBar(false);
        setIsLoading(false);
    },
    );
};

useEffect(() => {
    if (props.isOpen) {
        downloadData();
      }
}, [props.isOpen]);


  return {
    data,
    setData,
    goToEarringStats,
    isLoading,
    messages,
    formatMessage,
    classes
};


}