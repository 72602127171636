import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import {addLocaleData, FormattedMessage} from "react-intl";
import { ThemeProvider } from '@material-ui/core/styles';
import {theme} from '../src/assets/theme'
//Components
import AppBar from './components/common/AppBar';
import Footer from './components/common/Footer';
import Root from './Root';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
//views
import MenuView from "./components/main/Menu";
import LoginView from "./components/main/Login";
//translations
import messages_pl from "./translations/pl";
import messages_en from "./translations/en";
import locale_pl from "react-intl/locale-data/pl";
import locale_en from "react-intl/locale-data/en";

import './style/sass/login.scss'
import './style/sass/main.scss'
import './style/sass/drop_down.scss'
import './style/sass/datepicker.scss'
import './style/sass/graph.scss'
import './style/sass/settings.scss'
import './style/sass/device.scss'

addLocaleData([...locale_pl, ...locale_en]);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            session: true,
            language: navigator.language.split(/[-_]/)[0]
        }
    }

    messages = {
        'pl': messages_pl,
        'en': messages_en
    };

    setSession() {
        setTimeout(function () {
            this.setState({session: true})
        }.bind(this), 2000)
    }

    callBackLanguage = (language) => {
        this.setState({language: language})
    }

    render() {
        return (
            <Root locale={this.state.language} messages={this.messages[this.state.language]}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={"main"}>
                        <AppBar
                            onLanguageChange={this.callBackLanguage}
                            language={this.state.language}
                            logoutCallback={this.callBackLogin}
                        />
                        <div className={"main_background"}>
                            {this.state.session ||
                            <div className={'logout-message'}>
                                {
                                    this.setSession()
                                }
                                {sessionStorage.getItem("username") === null ?
                                    <FormattedMessage id={'main.logout'}/> :
                                    <FormattedMessage id={'main.logout_session'}/>}
                            </div>
                            }

                            <Route exact path={'/'} render={() => sessionStorage.getItem("token") ?
                                <Redirect to={"/menu"}/> :
                                <Redirect to={"/login"}/>}
                            />
                            <Route exact path={"/login"} component={LoginView}/>
                            <PrivateRoute path="/menu"
                                          callBack={this.callBackLogin}
                                          component={MenuView}/>

                        </div>
                        <Footer/>
                    </div>
                </MuiPickersUtilsProvider>
            </Root>
        );
    }
}

const PrivateRoute = ({component: Component,  callBack, ...rest}) => (

    <Route
        {...rest}
        render={props =>
            sessionStorage.getItem("token") ? (
                <Component {...props}  callBack={callBack}/>
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);
export default App;
