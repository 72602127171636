import React from "react";

import BuildingCardView from "../building/BuildingCard";
import BuildingParser from "../../data/parser/ParserBuilding";
import {useDispatch} from "react-redux";
import {sessionExpired} from "../../actions/auth";

import {request} from "../../net/http_client";
import {BUILDING_REQUEST} from "../../config";

import Grid from "@material-ui/core/Grid";
import Loader from "../loader/Loader";


function PersonalDataView(props) {
	const [buildings, setBuildings] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const dispatch = useDispatch();

	React.useEffect(() => {
		request(BUILDING_REQUEST, "GET", null).then((response) => {
			switch (response.status) {
			case 401:
				sessionStorage.removeItem("token");
				// sessionStorage.removeItem('username');
				dispatch(sessionExpired());

				break;

			case 200:
				const parser = new BuildingParser();
				setBuildings(parser.parser(response.data));
				break;

			default:
				break;
			}

			setLoading(false);
		});
	}, []);

	if (buildings.length) {
		return (
			<Grid container spacing={3} className='buildings-container'>
				{buildings.map((item) => {
					return (
						<Grid item xs={12} sm={6} md={4} key={item.state.id}>
							<BuildingCardView
								title={item.state.building_name}
								id={item.state.id}
								details={[item.state.counter_pen, item.state.counter_alarm]}/>
						</Grid>
					);
				})}
				<Loader visible={loading}/>
			</Grid>
		);
	}

	return null;
}

export default PersonalDataView;
