import React from "react";

import {withStyles} from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

const CustomSlider = withStyles({
	root: {
		color: "#52af77",
		height: 8,
	},
	thumb: {
		"height": 24,
		"width": 24,
		"backgroundColor": "#fff",
		"border": "2px solid currentColor",
		"marginTop": -8,
		"marginLeft": -12,
		"&:focus,&:hover,&$active": {
			boxShadow: "inherit",
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 4px)",
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider);

export default function TemperatureSlider(props) {
	const {
		label,
		value,
		onChange,
	} = props;

	return (
		<div>
			<Typography gutterBottom>{label}</Typography>
			<CustomSlider
				valueLabelDisplay="auto"
				value={value}
				min={0}
				max={45}
				onChange={onChange}
			/>
		</div>
	);
}
