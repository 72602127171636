import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    monthFormControl: {
        margin: theme.spacing(1),
        minWidth: 160,
        borderRadius: 15,
        padding: '2px 10px',
        backgroundColor: '#224f52', 
        color: 'white', 
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white', 
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'white', 
        },
        '& .MuiInput-input': {
            color: 'white', 
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            padding: '10px',
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiSelect-select': {
            color: 'white',
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiSvgIcon-root': {
            color: 'white', 
        },
    },
    yearFormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: 15,
        padding: '2px 10px',
        backgroundColor: '#224f52',
        color: 'white', 
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white', 
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'white', 
        },
        '& .MuiInput-input': {
            color: 'white',
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            padding: '10px',
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiSelect-select': {
            color: 'white', 
            fontFamily: 'Raleway, sans-serif',
        },
        '& .MuiSvgIcon-root': {
            color: 'white', 
        },
    },
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white', 
        },
    },
}));