import React, {useEffect, useState} from "react";
import {request} from "../../net/http_client";
import Loader from "../loader/Loader";
import {DEVICE_INFO_DETAILS, DEVICE_REQUEST} from "../../config";
import {sessionExpired} from "../../actions/auth";
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";

export default function SettingsDevice() {
	const dispatch = useDispatch();
	const [device, setDevice] = useState([]);
	const [progressBar, setProgressBar] = useState(false);
	useEffect(() => {
		setProgressBar(true);
		request(DEVICE_REQUEST, "GET").then((response) => {
			setProgressBar(false);
			if (response.status === 200) {
				setDevice(response.data);
			} else if (response.status === 401) {
				sessionStorage.removeItem("token");
				dispatch(sessionExpired());
			}
		});
	}, []);

	const update = (id) => {
		const data=device[id].device_info;
		delete data["install_type"];
		delete data["device_install_type"];
		request(DEVICE_INFO_DETAILS, "PUT", data, device[id].id).then((response) => {
			setProgressBar(false);
			if (response.status === 401) {
				sessionStorage.removeItem("token");
				dispatch(sessionExpired());
			}
		});
	};
	const changeValue = (id, value) => {
		const array = [...device];
		array[id].device_info.is_enabled = value;
		setDevice(array);
	};
	const changeName = (id, value) => {
		const array = [...device];
		array[id].device_info.name = value;
		setDevice(array);
	};
	return (<div className="device-main-view">
		<Grid container spacing={3} className='pens-container' justify='center'>
			{device.map((element, index) =>
				<Grid item xs={12} sm={6} md={4}>
					<Card>
						<div className="card-body">
							<h4>Urządzenie pomiarowe: {index + 1}</h4>
							<TextField style={{padding: 0, margin: 5}}
								onChange={(e) => changeName(index, e.target.value)} InputLabelProps={{
									shrink: true,
								}} label="Nazwa urządzenia" variant="outlined" className="form-control" type="text"
								id="start"
								name="trip-start" value={element.device_info.name}/>
							<TextField style={{padding: 0, margin: 5}} InputLabelProps={{
								shrink: true,
							}} label="Mac Address" variant="outlined" className="form-control" type="text" id="start"
							disabled={true}
							name="trip-start" value={element.device_network_info.mac_address_id}/>
							<FormControlLabel
								control={
									<Switch
										checked={element.device_info.is_enabled}
										onChange={(e) => changeValue(index, e.target.checked)}
										name="checkedB"
										color="primary"
									/>
								}
								label="Aktywne"
							/>
							<Button variant="contained" color="primary" onClick={()=>update(index)}>Zapisz</Button>
						</div>
					</Card></Grid>)}
		</Grid>
		<Loader visible={progressBar}/></div>);
}
