import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PenCardView from "../details/PenCard";
import {toast, ToastContainer} from "react-toastify";
import {Redirect} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import NavBar from "../common/NavBar";
import {sessionExpired} from "../../actions/auth";

import {EXTERNAL_SENSOR_DATA, PEN_REQUEST_BUILDING_DETAILS, SETTINGS_TOASTER} from "../../config";
import {request} from "../../net/http_client";
import {Line} from "react-chartjs-2";
import Card from "@material-ui/core/Card/Card";


function PenView(props) {
	const [pens, setPens] = React.useState(null);
	const dispatch = useDispatch();
	const building = useSelector((store) => store.buildings.building);
	const [externalSensor, setExternalSensor] = React.useState([]);
	const max = [0, 0, 0]; const min = [100, 100, 100];

	React.useEffect(() => {
		request(PEN_REQUEST_BUILDING_DETAILS, "GET", null, building.id).then((response) => {
			switch (response.status) {
			case 401:
				sessionStorage.removeItem("token");
				dispatch(sessionExpired());
				toast.info("Session expired ", SETTINGS_TOASTER);
				break;
			case 200:
				setPens(response.data);
				break;

			default:
				break;
			}
		});
		request(EXTERNAL_SENSOR_DATA, "GET", null, building.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l").toLowerCase(), Date.now() / 1000 - 24 * 7 * 3600, Date.now() / 1000).then((response) => {
			switch (response.status) {
			case 401:
				sessionStorage.removeItem("token");

				dispatch(sessionExpired());
				toast.info("Session expired ", SETTINGS_TOASTER);
				break;
			case 200:
				setExternalSensor(response.data);
				break;

			default:
				break;
			}
		});
	}, []);
	const parse_data = () => {
		const data = [];
		data.push({
			label: "pm1", data: [], backgroundColor: "transparent",
			borderColor: "rgba(255, 0, 0,1.0)", showLine: false,
			pointRadius: 1, borderWidth: 1, borderRadius: 5, pointHoverRadius: 5,
		});
		data.push({
			label: "pm2.5", data: [], backgroundColor: "transparent",
			borderColor: "rgba(0, 255, 0,1.0)",
			pointRadius: 1, borderWidth: 1, borderRadius: 1, showLine: false, pointBorderWidth: 1,
		});
		data.push({
			label: "pm10", data: [], backgroundColor: "transparent",
			borderColor: "rgba(0, 0, 255,1.0)",
			pointRadius: 1, borderWidth: 1, borderRadius: 1, showLine: false,
		});
		for (let x = 0; x < externalSensor.length; x++) {
			for (let xx = 0; xx < externalSensor[x].data.length; xx++) {
				if (externalSensor[x].data[xx].value > max[xx]) {
					max[xx] = externalSensor[x].data[xx].value;
				}
				if (externalSensor[x].data[xx].value < min[xx]) {
					min[xx] = externalSensor[x].data[xx].value;
				}
				data[xx].data.push({y: externalSensor[x].data[xx].value, t: externalSensor[x].timestamp});
			}
		}
		return data;
	};

	function callBackRefresh() {
		toast.info("Zarejestrowano interwencję ", SETTINGS_TOASTER);
	}

	if (!building) {
		return <Redirect to="/menu/user" push/>;
	}
	if (pens && pens) {
		return (
			<div className="device-main-view">

				<Grid container spacing={3} className='pens-container' justify='center'>
					<NavBar locationName={building.name}/>

					{pens.map((item, index) => {
						return (
							<Grid item xs={12} sm={6} md={4}>
								<PenCardView
									pen={item.pen}
									buildingId={building.id}
									buildingTitle={building.name}
									externalSensor={externalSensor.length !== 0 && externalSensor[externalSensor.length - 1].data}
									key={index}
									details={[item.sick, item.ambient_temp]}
									refresh={callBackRefresh}/>
							</Grid>
						);
					})}
					<ToastContainer position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover={false}/>
				</Grid>
				{/* <div style={{display: "flex", margin: 10}}>
					{parse_data().map((element, index) => <Card style={{margin: 5}}><div style={{flex: 1}}><Line
						data={{
							"datasets": [element],
						} || {}}
						options={{
							scales: {
								xAxes: [{
									type: "time",
									time: {
										unit: "hour",
										displayFormats: {
											hour: "HH",
										},
									},
								}],
								yAxes: [{
									ticks: {
										min: min[index] - 5,
										max: max[index] + 5,
									},
								}],
							},
						}}
					/></div></Card>)}</div> */}
			</div>
		);
	}

	return null;
}

export default PenView;
