import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  '@global': {
    '*': {
        fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTable-root': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTableCell-root': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-h6': {
      fontFamily: 'Raleway, sans-serif',
    },
    '.MuiTypography-body1': {
      fontFamily: 'Raleway, sans-serif',
  },
  '.MuiTypography-root': {
      fontFamily: 'Raleway, sans-serif',
  },
  '.MuiButton-root': {
    fontFamily: 'Raleway, sans-serif',
  },
},
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '15px',
    },
    modalContent: {
      maxHeight: 'calc(100vh - 300px)',
      overflowY: 'auto',
    },
    modalTitle: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    animalId: {
      color: '#224f52',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    tableHeading: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      color: theme.palette.text.primary,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      textAlign: 'center',
    },
    tableContainer: {
      maxHeight: '400px',
      overflow: 'auto',
      marginBottom: theme.spacing(2),
    },
    tableHeaderCell: {
      backgroundColor: '#224f52', 
      color: 'white',
    },
  }));