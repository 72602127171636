import {useState, useEffect} from "react";
import {getWeekNumber, getFirstWeekOfMonth, getLastWeekOfMonth, getStartAndEndDateOfWeek} from "../../helpers/dateTimeHelpers";
import {defineMessages} from "react-intl";

export const useBarChart = (props) => {
    const [chartWidth, setChartWidth] = useState(getChartWidth());
    const formatMessage = props.intl.formatMessage;


    const messages = defineMessages({
      weekdays: {
        id: "calendar.days",
      },
      previousWeek: {
        id: "week.previous"
      },
      nextWeek: {
        id: "week.next"
      }
    });

    useEffect(() => {
        function handleResize() {
            setChartWidth(getChartWidth());
          }
          window.addEventListener('resize', handleResize);
          handleResize();
      
          return () => window.removeEventListener('resize', handleResize);
    })

    function getChartWidth() {
        const screenWidth = window.innerWidth;
        if (screenWidth > 1920) {
          return 900; 
        } else if (screenWidth > 768) {
          return 600; 
        } else {
          return screenWidth;
        }
      }

    const firstWeekOfMonth = getFirstWeekOfMonth(props.year, props.month);
    const lastWeekOfMonth = getLastWeekOfMonth(props.year, props.month);

    const [currentWeek, setCurrentWeek] = useState(firstWeekOfMonth);

    useEffect(() => {
        const newFirstWeekOfMonth = getFirstWeekOfMonth(props.year, props.month);
        setCurrentWeek(newFirstWeekOfMonth);
    }, [props.month, props.year]);

      const handleWeekChange = (newWeek) => {
        if (newWeek >= firstWeekOfMonth && newWeek <= lastWeekOfMonth) {
            setCurrentWeek(newWeek);
        }
    };

    const handleNextWeekClick = () => {
        handleWeekChange(currentWeek + 1);
    };

    const handlePreviousWeekClick = () => {
        handleWeekChange(currentWeek - 1);
    };

    const processDataForBarChart = (datesData, currentWeek) => {
        const countsByWeekday = Array.from({ length: 7 }, () => 0);
    
        Object.entries(datesData).forEach(([dateStr, count]) => {
            const date = new Date(dateStr);
            const weekNumber = getWeekNumber(date);
            if (weekNumber === currentWeek) {
                const dayIndex = (date.getDay() + 6) % 7;
                countsByWeekday[dayIndex] += count;
            }
        });
        
        return formatMessage(messages.weekdays).map((day, index) => {
            return { day, ilość: countsByWeekday[index] || 0 };
        });
    };

    const data = processDataForBarChart(props.dates, currentWeek);

    const dateRangeText = getStartAndEndDateOfWeek(currentWeek, props.year);

        return {
            chartWidth,
            currentWeek,
            data,
            handleNextWeekClick,
            handlePreviousWeekClick,
            dateRangeText,
            firstWeekOfMonth,
            lastWeekOfMonth,
            messages,
            formatMessage,
        };
}