import axios from "axios";
import {SIGN_IN, URL} from "./../config";


export const login_request = (username, password) => {
	const urlRequest = get_request_url(SIGN_IN, []);
	return axios({
		method: "post",
		url: urlRequest,
		data: {username: username, password: password},
		validateStatus: (status) => {
			return session(status);
		},
	});
};

const get_request_url = (url, params) => {
	if (params === []) {
		return url.replace(/%s/g, URL());
	} else {
		params.unshift(URL());
		return url.replace(/%s/g, function() {
			return params.shift();
		});
	}
};

export const get = (url) => {
	return axios({
		method: "get",
		url: URL() + url,
		headers: {
			"Authorization": sessionStorage.getItem("token"),
		},
		validateStatus: (status) => {
			return status;
		},
	}).then((response) => {
		return session(response);
	}).catch((error) => {
		return session(error);
	});
};
export const deleteData = (url) => {
	return axios({
		method: "delete",
		url: URL() + url,
		headers: {
			"Authorization": sessionStorage.getItem("token"),
		},
		validateStatus: (status) => {
			return status;
		},
	}).then((response) => {
		return session(response);
	}).catch((error) => {
		return session(error);
	});
};
export const post = (url, data) => {
	return axios({
		method: "post",
		url: URL() + url,
		data: data,
		headers: {
			"Authorization": sessionStorage.getItem("token"),
		},
		validateStatus: (status) => {
			return status;
		},
	}).then((response) => {
		return session(response);
	}).catch((error) => {
		return session(error);
	});
};
export const put = (url, id, data) => {
	return axios({
		method: "put",
		url: URL() + url,
		data: data,
		headers: {
			"Authorization": sessionStorage.getItem("token"),
		},
		validateStatus: (status) => {
			return status;
		},
	}).then((response) => {
		return session(response);
	}).catch((error) => {
		return session(error);
	});
};

export const request = (url, method, data, ...params) => {
	const urlRequest = get_request_url(url, params);
	return axios({
		method: method,
		url: urlRequest,
		data: data,
        timeout: 1000000000,
		headers: {
			"Authorization": sessionStorage.getItem("token"),
		},
		validateStatus: (status) => {
			return session(status);
		},
	});
};

const session = (response) => {
	if (response.status === 401) {
		sessionStorage.clear();
	}
	return response;
};


