import {useState, useEffect} from "react";
import {request} from "../../net/http_client";
import {FEEDING_ANIMAL_DATA_GRAPH} from "../../config";
import {sessionExpired} from "../../actions/auth";
import {useDispatch} from "react-redux";
import {defineMessages} from "react-intl";


export const useChartFeeding = (props) => {

    const [data, setData] = useState([]);
    const [graphData,setGraphData]=useState([]);
    const dispatch = useDispatch();
    const formatMessage = props.intl.formatMessage;

    const messages = defineMessages({
      feeding_time: {
        id: "patient.feeding_time",
      },
      ambient_temp: {
        id: "patient.ambient",
      },
    });


    const downloadData = () => {
      props.setProgressBar(true);
        const startDate = new Date(props.year, props.month, 2).toISOString().slice(0, 10);
        const endDate = new Date(props.year, props.month + 1, 2).toISOString().slice(0, 10);
        request(FEEDING_ANIMAL_DATA_GRAPH, "GET", [], startDate, endDate).then((response) => {
                if (response.status === 200) {
                    setData(response.data);
                } else if (response.status === 401) {
                    sessionStorage.removeItem("token");
                    dispatch(sessionExpired());
                }
                props.setProgressBar(false);
            },
        );
    };

    useEffect(() => {
      downloadData()
    }, [props.year, props.month])
    useEffect(()=>{
        let temp_data=[[],[],[]];
        for(let x=0;x<data.length;x++)
        {
            temp_data[0].push(data[x].ambient_temp);
            temp_data[1].push(parseInt(data[x].feeding_time));
            temp_data[2].push(data[x].time.slice(0, 16));
        }
        setGraphData(temp_data);
    },[data])

    return {
        data,
        graphData,
        messages,
        formatMessage,
    };
}