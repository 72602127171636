import {
	SELECT_BUILDING,
	SELECT_EARRING,
	SELECT_PEN,
} from "./actionTypes";

export function selectBuilding(building) {
	return {
		type: SELECT_BUILDING,
		payload: {
			building,
		},
	};
}

export function selectPen(pen) {
	return {
		type: SELECT_PEN,
		payload: {
			pen,
		},
	};
}

export function selectEarring(earring) {
	return {
		type: SELECT_EARRING,
		payload: {
			earring,
		}
	}
}
