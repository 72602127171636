import React from "react";
import {compose} from "recompose";
import {FormattedMessage, injectIntl, defineMessages} from "react-intl";
import {PEN_REQUEST_FIX_ALARM} from "../../config";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {selectPen} from "../../actions/buildings";

import Card from "../common/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ConfirmInterventionDialog from "./ConfirmInterventionDialog";
import {request} from "../../net/http_client";

function PenCard(props) {
	const dispatch = useDispatch();

	const [dialogOpen, setDialogOpen] = React.useState(false);

	const handleDialogOpen = () => setDialogOpen(true);
	const handleDialogClose = () => setDialogOpen(false);

	function resolveAlarmEvent() {
		request(PEN_REQUEST_FIX_ALARM, "PUT", null, props.pen).then((response) => {
			switch (response.status) {
			case 401:
				sessionStorage.removeItem("token");
				break;
			case 303:
				props.details[0]=0;
				break;

			default:
				break;
			}

			handleDialogClose();
		});
	}

	const handleGoToDevice = () => {
		dispatch(selectPen({id: props.pen}));
		props.history.push("/menu/device");
	};

	const formatMessage = props.intl.formatMessage;
	const messages = defineMessages({
		details: {
			id: "pencard.details_tooltip",
		},
		intervention: {
			id: "pencard.intervention_tooltip",
		},
		penTitle: {
			id: "details.title",
		},
	});
	return (
		<Card
			title={formatMessage(messages.penTitle)}
			label={`id: ${props.pen}`}
			cardActions={(
				<React.Fragment>

					<Tooltip title={formatMessage(messages.details)}>
						<Button
							onClick={handleGoToDevice}
							variant='contained'
							color='secondary'>
							<FormattedMessage id={"details.details"}/>
						</Button>
					</Tooltip>

					<Tooltip title={formatMessage(messages.intervention)}>
						<Button
							style={{marginRight: 10}}
							onClick={handleDialogOpen}
							variant='contained'
							color='secondary'>
							<FormattedMessage id={"details.intervention"}/>
						</Button>
					</Tooltip>

				</React.Fragment>
			)}
		>
			<div className="card-body">
				{/* <img className={'sign_image'} src={require('./../../resources/sign_green.svg')}/>*/}
				<div id={"alarm"} className={"div_subtitle"}>
					<img className={"alarm_img"}
						alt='alarm'
						src={props.details[0] === 0 ? require("./../../resources/pig_ok.svg") : require("./../../resources/pig_bad.svg")}/>
					<p className="card-text"><FormattedMessage id={"details.alerts"}/>: {props.details[0]}</p>
				</div>
				<div id={"temp"} className={"div_subtitle"}>
					<img className={"alarm_img"}
						alt='alarm'
						src={require("./../../resources/temp_inside.svg")}/>
					<p className="card-text">
						<FormattedMessage id={"details.temperature"}/>: {Number(props.details[1]).toFixed(1)}</p>
				</div>
				<div style={{display: "flex"}}>
					<img className={"alarm_img"}
						alt='air'
						src={require("./../../resources/air.svg")}/>
					{props.externalSensor?props.externalSensor.map((element)=><div style={{margin: 5}}>{element.type}: {element.value}</div>):"Brak danych"}
				</div>
				<ConfirmInterventionDialog open={dialogOpen} onClose={handleDialogClose} onConfirm={resolveAlarmEvent}/>
			</div>
		</Card>);
}

export default compose(
	injectIntl,
	withRouter,
)(PenCard);
