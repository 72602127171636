import React from "react";

import LogoSpin from "../../resources/logo_spin.svg";
import SpinSmall from "../../resources/spin_small.svg";

import "./Loader.scss";

export default function Loader(props) {
	if (props.visible) {
		return (
			<div className='opacity-bg'>
				<img src={LogoSpin} className='loader' alt='pig-loader'/>
				<img src={SpinSmall} className='spinner' alt='pig-spinner-nose' />
			</div>
		);
	}

	return null;
}
