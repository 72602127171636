import React from "react";
import PropTypes from "prop-types";
import {compose} from "recompose";
import {NavLink, withRouter} from "react-router-dom";

import {FormattedMessage, defineMessages, injectIntl} from "react-intl";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

// Material-UI Components
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";

import "./NavBar.scss";

function NavBar({match, locationName, history, penId, intl}) {
	const breadcrubs = match.path.slice(1).split("/");

	const formatMessage = intl.formatMessage;

	const messages = defineMessages({
		goBack: {
			id: "navbar.go_back",
		},
		pagination: {
			id: "navbar.pagination",
		},
		building: {
			id: "navbar.building_name",
		},
		penId: {
			id: "navbar.pen_id",
		},
		device: {
			id: "navbar.device",
		},
		pen: {
			id: "navbar.pen",
		},
		menu: {
			id: "navbar.menu",
		},
	});

	return (
		<div className="view-navbar">

			<div className="view-navbar-group">

				<Tooltip title={formatMessage(messages.goBack)}>
					<button className='back-button' onClick={() => history.goBack()}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</button>
				</Tooltip>

				<Hidden xsDown>
					<Tooltip title={formatMessage(messages.pagination)}>
						<nav aria-label="breadcrumb" className='navbar-breadcrumb-wrapper'>
							<ol className="breadcrumb navbar-breadcrumb view-navbar-item">
								{
									breadcrubs.map((item, index) => (
										<NavLink
											exact
											className="breadcrumb-item"
											key={index}
											to={"/"+breadcrubs.slice(0, index+1).join("/")}> {formatMessage(messages[item.toLowerCase()])} </NavLink>
									))
								}
							</ol>
						</nav>
					</Tooltip>
				</Hidden>

			</div>

			<div className="view-navbar-group">

				{ penId && (
					<Tooltip title={formatMessage(messages.penId)}>
						<div className="view-navbar-item navbar-pen-id">
							<FormattedMessage id='details.title' />: {penId}
						</div>
					</Tooltip>
				)}

				<Tooltip title={formatMessage(messages.building)}>
					<div className="view-navbar-item">
						{locationName}
					</div>
				</Tooltip>

			</div>

		</div>
	);
}

NavBar.propTypes = {
	match: PropTypes.object.isRequired,
	locationName: PropTypes.string.isRequired,
	penId: PropTypes.number,
	history: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	injectIntl,
)(NavBar);
