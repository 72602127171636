import React from "react";
import PropTypes from "prop-types";

import {Line} from "react-chartjs-2";
import * as zoom from "chartjs-plugin-zoom";

function Chart(props) {
	const {
		title,
		timeUnit,
		data,
		onZoomComplete,
		setRef,
		minYScale,
		maxYScale,
	} = props;


	return (
		<Line
			ref={(reference) => setRef(reference)}
			data={data || {}}
			options={{
				tooltips: {
					mode: "index",
					intersect: false,
					callbacks: {
						label: function(tooltipItem, data) {
							if (data.datasets[tooltipItem.datasetIndex].label === "logs") {
								return data.datasets[tooltipItem.datasetIndex].label + ":" + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].message;
							}
							return data.datasets[tooltipItem.datasetIndex].label + ":" + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
						},
					},
				},
				title: {
					display: true,
					fontSize: 20,
					text: title,
				},
				scales: {
					xAxes: [{
						type: "time",
						time: {
							unit: timeUnit,
							displayFormats: {
								hour: "HH",
							},
						},
					}],
					yAxes: [{
						ticks: {
							min: minYScale,
							max: maxYScale,
						},
					}],
				},
				pan: {
					enabled: true,
					onPanComplete: onZoomComplete,
					mode: "x",
				},
				zoom: {
					enabled: true,
					speed: 0.1,
					drag: {
						borderColor: "rgba(225,225,225,0.3)",
						borderWidth: 5,
						backgroundColor: "rgb(225,225,225)",
					},

					onZoomComplete: onZoomComplete,
				},
			}}
		/>
	);
}

Chart.propTypes = {
	title: PropTypes.string,
	data: PropTypes.object.isRequired,
	timeUnit: PropTypes.string,
};

Chart.defaultProps = {
	timeUnit: "hour",
};

export default Chart;
