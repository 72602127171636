import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {request} from "../../net/http_client";
import {USER_REQUEST_DETAILS} from "../../config";
import {withRouter} from 'react-router-dom'
import {compose} from "recompose";

class UserSettingsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            email: "",
            pass: "",
            pass1: "",
            pass_error: false,
            equals_error: false,
            email_error: false,
            oldEmail: ""
        };

        request(USER_REQUEST_DETAILS, "GET", null, sessionStorage.getItem("id")).then(response => {
            switch (response.status) {

                case 401:
                    sessionStorage.removeItem('token');
                    this.props.history.push('/login');
                    break;

                case 400:
                    if (response.data.error === "Empty id")
                    //ToDo show info that empty id
                    {
                        sessionStorage.removeItem('token');
                        this.props.history.push('/login');
                    }
                    break;

                case 200:
                    let data = response.data;
                    this.setState({username: data.username, email: data.email, oldEmail: data.email})
                    break;

                default:
                    break;

            }
        })
    }

    sendUpdate() {
        let data = {email: this.state.email, password: this.state.pass};
        request(USER_REQUEST_DETAILS, "PUT", data, sessionStorage.getItem("id")).then(response => {
            switch (response.status) {
                case 303:
                    this.props.toaster("Update Success", this.props.statusToaster.Success);
                    break;
                case 400:
                    this.props.toaster("Bad email address", this.props.statusToaster.Error);
                    break;

                default:
                    break;

            }
        })
    }

    validePass() {
        if (this.state.pass === "") {
            this.setState({pass_error: true});
            return false
        }
        else if (this.state.pass !== this.state.pass1) {
            this.setState({equals_error: true, pass_error: false, pass1: ""});
            return false
        }
        return true
    }

    onClick() {
        if (this.state.email === "") {
            this.setState({email_error: true});
            this.validePass();
            return
        }
        else {
            if (!this.validePass()) {
                return
            }
            this.sendUpdate()
        }
    }

    handleChangeEmail = event => {
        this.setState({email_error: false, email: event.target.value});
    };
    handleChangePass = event => {
        this.setState({pass_error: false, pass: event.target.value});
    };
    handleChangePass1 = event => {
        this.setState({equals_error: false, pass1: event.target.value});
    };

    render() {
        return (
            <div>
                <form className={"form_settings"}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"><FormattedMessage id={"settings.username"}/></label>
                        <input type="text" className="form-control" id="username"
                               aria-describedby="emailHelp" placeholder="Username" value={this.state.username}
                               disabled={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"><FormattedMessage id={"settings.email"}/></label>
                        <input type="email" className="form-control" id="email" value={this.state.email}
                               onChange={this.handleChangeEmail.bind(this)}
                               aria-describedby="emailHelp"
                               placeholder={this.props.intl.formatMessage({id: 'settings.enter_email'})} required/>
                        <small id="emailHelp" className="form-text error">
                            {this.state.email_error && <FormattedMessage id={"settings.email_error"}/>}
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1"><FormattedMessage id={"settings.password"}/></label>
                        <input type="password" className="form-control" id="pass" value={this.state.pass}
                               onChange={this.handleChangePass.bind(this)}
                               placeholder={this.props.intl.formatMessage({id: 'settings.password'})}/>
                        <small id="emailHelp" className="form-text error">
                            {this.state.pass_error && <FormattedMessage id={"settings.pass_error"}/>}
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1"><FormattedMessage
                            id={"settings.repeat_password"}/></label>
                        <input type="password" className="form-control" id="pass1" value={this.state.pass1}
                               onChange={this.handleChangePass1.bind(this)}
                               placeholder={this.props.intl.formatMessage({id: 'settings.repeat_password'})}/>
                        <small id="emailHelp" className="form-text error">
                            {this.state.equals_error && <FormattedMessage id={"settings.equals_error"}/>}
                        </small>
                    </div>
                </form>
                <button className="btn btn-primary" onClick={this.onClick.bind(this)}>
                    <FormattedMessage id={"device.save"}/>
                </button>
            </div>
        );
    }
}

export default compose(
    injectIntl, withRouter
)(UserSettingsView)
