import React from "react";
import PropTypes from "prop-types";

import {useDispatch, useSelector} from "react-redux";
import {toggleSidebar} from "../../actions/common";

import {FormattedMessage} from "react-intl";

import LogoutView from "../logic/Logout";
import ChangeLanguageView from "../logic/ChangeLanguage";

import AppBarComponent from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";

import "../../style/sass/app_bar.scss";


function AppBar(props) {
	const {
		onLanguageChange,
		language,
	} = props;

	const dispatch = useDispatch();
	const isLoggedIn = useSelector((store) => store.auth.loggedIn);

	function handleMenuToggle() {
		dispatch(toggleSidebar());
	}

	return (
		<AppBarComponent className='appbar'>
			<Toolbar className='appbar-toolbar'>
				<div className="main_head_group">
					<Tooltip title='Toggle menu'>
						<IconButton onClick={handleMenuToggle} className='menu-button'>
							<MenuIcon className='menu-icon'/>
						</IconButton>
					</Tooltip>
					<Hidden xsDown>
						<div className={"main_logo"}/>
					</Hidden>
				</div>

				<div className={"main_head_text"}>

					<Hidden smDown>
						<FormattedMessage id={"main.head_text"}/>
					</Hidden>

					<ChangeLanguageView callBackLanguage={onLanguageChange} language={language}/>

					{isLoggedIn && <LogoutView />}
				</div>
			</Toolbar>
		</AppBarComponent>
	);
}

AppBar.propTypes = {
	onLanguageChange: PropTypes.func.isRequired,
	language: PropTypes.string.isRequired,
};

export default AppBar;
