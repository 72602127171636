export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const SELECT_BUILDING = "SELECT_BUILDING";

export const SELECT_PEN = "SELECT_PEN";

export const SELECT_EARRING = "SELECT_EARRING"

// auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
