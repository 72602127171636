import React from "react";
import {FormattedMessage} from "react-intl";
import {Redirect, withRouter} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {SETTINGS_TOASTER} from "../../config";

import {connect} from 'react-redux';
import {compose} from 'recompose';
import {login} from '../../actions/auth';
import {login_request} from "../../net/http_client";

class LoginView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            inputStyle: "form-control",
            success: true
        }

    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFormSubmit = (ev) => {

        ev.preventDefault();

        login_request(this.state.login, this.state.password).then((response) => {

            switch (response.status) {

                case 200:
                    sessionStorage.setItem("token", response.data.token);
                    sessionStorage.setItem("username", this.state.login);
                    sessionStorage.setItem("id", response.data.id);
                    this.props.login();
                    this.props.history.push('/menu/user');
                    // sessionStorage.setItem("id",null);
                    break;
                case 400:
                    this.setState({inputStyle: 'form-control error-login', success: false});
                    break;

                default:
                    break;
            }
        })
            .catch(error => {
                toast.error("Server Error. Contact on kontakt@thermoeye.pl", SETTINGS_TOASTER);
            });

    }


    render() {
        if (sessionStorage.getItem("token") !== null) {
            return <Redirect to="/menu"/>
        }

        return (
            <div className={'card'}>
                <div className={'card-title'}>
                    <FormattedMessage id={'login.title'}/>
                </div>
                <form className={'form-signin'} onSubmit={this.handleFormSubmit}>

                    <div className="form-label-group login-form-div">
                        <input type="text" id="login" className={this.state.inputStyle} value={this.state.login}
                               onChange={this.handleChange} placeholder="login" required autoFocus/>
                    </div>

                    <div className={"form-label-group login-form-div"}>
                        <input type="password" id="password" className={this.state.inputStyle}
                               value={this.state.password}
                               onChange={this.handleChange} placeholder="password" required autoFocus/>
                    </div>

                    <button
                        id={'event_button'}
                        className="btn btn-lg btn-primary btn-block text-uppercase login-form-div"
                        type='submit'>

                        <FormattedMessage id={'login.signin'}/>

                    </button>

                </form>
                {
                    this.state.success ||
                    <span className={"span-invalid"}><FormattedMessage id={'login.invalid'}/></span>
                }

                <ToastContainer position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover={false}/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    login: () => dispatch(login())
})

export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(LoginView)
