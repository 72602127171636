import React from "react";

import {FormattedMessage} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";

import logo from "../../resources/pig_logo.svg";

const useStyles = makeStyles({
	logo: {
		width: 40,
		height: 40,
		marginRight: 20,
		marginLeft: 20,
	},
});

function Footer(props) {
	const classes = useStyles();

	return (
		<div className="main_foot">

            Copyright &copy; 2019
			<img src={logo} alt='footer-logo' className={classes.logo} />
            Thermoeye by Smart Soft Solutions Sp. z o. o. &nbsp;
			<FormattedMessage id={"app.copyright"}/>
		</div>
	);
}

export default Footer;
