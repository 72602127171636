import React from "react";

import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toggleSidebar} from "../../actions/common";
import clsx from "clsx";
// Material-UI Components
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";


import PigLogo from "../../resources/pig_logo.svg";
import DeviceSettings from "../../resources/settings.svg";
import SettingsIcon from "../../resources/settings_icon.svg";
import PatientCardIcon from "../../resources/patient_card.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {FormattedMessage} from "react-intl";

const routes = [
	{
		text: "menu.home",
		path: "/menu/user",
		icon: PigLogo,
	},
	{
		text: "menu.patient",
		path: "/menu/patient",
		icon: PatientCardIcon,
	},
	{
		text: "Urządzenia",
		path: "settings/device",
		icon: DeviceSettings,
	},
	{
		text: "menu.settings",
		path: "/menu/settings",
		icon: SettingsIcon,
	},
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	hide: {
		display: "none",
	},
	paper: {
		marginTop: 80,
		backgroundColor: "#224f52",
	},
	mobilePaper: {
		backgroundColor: "#224f52",
		width: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		marginTop: 80,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	icon: {
		width: 40,
		height: 40,
	},
	menuItem: {
		"color": "#fff",
		"textDecoration": "none",

		"&:hover": {
			color: "#eee",
			textDecoration: "none",
		},
		"&.active": {
			background: "#00000033",
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
		...theme.mixins.toolbar,
		justifyContent: "space-between",
	},
	headIcon: {
		color: "#fff",
	},
	logo: {
		height: 30,
	},
}));

function Sidebar(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const open = useSelector((store) => store.common.sidebarIsOpen);
	const handleDrawerToggle = () => dispatch(toggleSidebar());

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const drawer = (
		<List>
			{routes.map((route, index) => (
				<ListItem component={NavLink} exact to={route.path} key={index} className={classes.menuItem}>
					<ListItemIcon>
						<img src={route.icon} alt={route.text + "-icon"} className={classes.icon}/>
					</ListItemIcon>
					<ListItemText>
						<FormattedMessage id={route.text}/>
					</ListItemText>
				</ListItem>
			))}
		</List>
	);

	return (
		<nav>
			<Hidden mdUp>
				<Drawer
					variant="temporary"
					disableBackdropTransition={!iOS} disableDiscovery={iOS}
					open={open}
					onClose={handleDrawerToggle}
					classes={{
						paper: classes.mobilePaper,
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					<div className={classes.drawerHeader}>
						<IconButton className={classes.headIcon} onClick={handleDrawerToggle}>
							<ChevronLeftIcon/>
						</IconButton>
						<img src={PigLogo} alt='Header Logo' className={classes.logo}/>
					</div>
					{drawer}
				</Drawer>
			</Hidden>
			<Hidden smDown>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx(classes.paper, {
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
					open={open}
				>
					{drawer}
				</Drawer>
			</Hidden>
		</nav>
	);
}

export default Sidebar;
