import React from "react";
import {compose} from "recompose";

import { Tooltip as MaterialTooltip }  from "@material-ui/core";

import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import '../../style/css/barchart.css'
import Typography from "@material-ui/core/Typography";
import { useBarChart } from "../../hooks/earring/useBarChart";
import {injectIntl} from "react-intl";


function DayBarChart(props) {
    const {
      chartWidth,
      currentWeek,
      data,
      handleNextWeekClick,
      handlePreviousWeekClick,
      dateRangeText,
      firstWeekOfMonth,
      lastWeekOfMonth,
      messages,
      formatMessage,
  } = useBarChart(props);
          
  return (
      <>
        <BarChart 
          width={chartWidth}
          height={300} 
          data={data} 
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          className="recharts-wrapper">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="ilość" fill="#39846A" barSize={30} />
        </BarChart>
        <div className="date-range-container">
          <MaterialTooltip title={dateRangeText} placement="top">
            <Typography variant="subtitle2" className="week-days">
              {dateRangeText}
            </Typography>
          </MaterialTooltip>
        </div>
        <div className="button-container"> 
          <Button 
            variant="contained" 
            color="primary" 
            className="week-button"
            onClick={handlePreviousWeekClick}
            disabled={currentWeek === firstWeekOfMonth}
          >
            <ArrowBackIosIcon /> {formatMessage(messages.previousWeek)}
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            className="week-button"
            onClick={handleNextWeekClick}
            disabled={currentWeek === lastWeekOfMonth}
          >
            {formatMessage(messages.nextWeek)} <ArrowForwardIosIcon />
          </Button>
        </div>
      </>
    );

}

export default compose(
	injectIntl,
)(DayBarChart);