import React from "react";

import {IntlProvider} from "react-intl";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import {createStore} from "redux";

import reducers from "./reducers";

const store = createStore(reducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const theme = createMuiTheme({
	palette: {
		primary: {
			contrastText: "#fff",
			main: "#224f52",
			light: "#4f7b7e",
			dark: "#00272a",
		},
		secondary: {
			contrastText: "#fff",
			main: "#419C8D",
			light: "#74cdbd",
			dark: "#006d60",
		},
	},
});

function Root(props) {
	const {
		children,
		locale,
		messages,
	} = props;

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<IntlProvider locale={locale} messages={messages}>
					<Router>
						{children}
					</Router>
				</IntlProvider>
			</ThemeProvider>
		</Provider >
	);
}

export default Root;
