import { useEffect, useState } from "react"
import { calculateMeanAmbientTemp } from "../../helpers/dateTimeHelpers";
import { request } from "../../net/http_client";
import { FEEDING_ANIMAL_DATA_TABLE, ANIMAL_TEMP } from "../../config";
import { sessionExpired } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { defineMessages} from "react-intl";
import { useStyles } from "../../style/componentsStyling/Patient.styles";



export const usePatient = (props) => {
    const [progressBar, setProgressBar] = useState(true);
    const [data, setData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [feedingTime, setFeedingTime] = useState(0);
    const [totalAttempts, setTotalAttempts] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const formatMessage = props.intl.formatMessage;

    const messages = defineMessages({
      feeding_time: {
        id: "patient.feeding_time",
      },
      ambient_temperature: {
        id: "patient.ambient",
      },
      total_attempts: {
        id: "patient.total_attempts",
      },
      no_data: {
        id: "patient.no_data",
      },
    });
    
    
    const classes = useStyles();
    const dispatch = useDispatch();


    const meanTemperature = calculateMeanAmbientTemp(tempData);

    const downloadData = async (selectedYear, selectedMonth) => {
        setIsLoading(true);
        setProgressBar(true);
        setYear(selectedYear);
        setMonth(selectedMonth);
    
        const startDate = new Date(selectedYear, selectedMonth, 2).toISOString().slice(0, 10);
        const endDate = new Date(selectedYear, selectedMonth + 1, 2).toISOString().slice(0, 10);

        try {
            const response = await request(FEEDING_ANIMAL_DATA_TABLE, "GET", [], startDate, endDate);
            if (response.status === 200 && response.data && response.data.data) {
                setData(response.data.data);
                setFeedingTime(response.data.feeding_time)
                setTotalAttempts(response.data.data.reduce((sum, item) => sum + item.attempts, 0))
            } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                dispatch(sessionExpired());
            }
            const responseTemp = await request(ANIMAL_TEMP, "GET", [], startDate, endDate);
            if (responseTemp.status === 200 && response.data) {
                setTempData(responseTemp.data);
            } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                dispatch(sessionExpired());
            }
        } catch (error) {
            console.log("Error downloading data", error);
        } finally {
            setProgressBar(false);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        downloadData(year, month);
    }, [])

    return {
        progressBar,
        setProgressBar,
        data,
        year,
        month,
        downloadData,
        isLoading,
        feedingTime,
        tempData,
        meanTemperature,
        totalAttempts,
        messages,
        classes,
        formatMessage,
    }
}