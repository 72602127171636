import React from "react";
import {Redirect} from "react-router-dom";
import {SIGN_OUT} from "./../../config"
import {toast, ToastContainer} from "react-toastify";
import {SETTINGS_TOASTER} from "../../config";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import logoutIcon from '../../resources/sign-out-alt-solid.svg';

import {request} from '../../net/http_client';
import {connect} from 'react-redux';
import {logout} from '../../actions/auth';

class LogoutView extends React.Component {

    constructor(props) {

        super(props);
    }

    handleLogout = () => {

        let data = {"username": sessionStorage.getItem("username")};
        request(SIGN_OUT, 'POST', data).then(response => {
            switch (response.status) {
                case 200:
                case 401:
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('username');
                    this.props.logout();
                    break;
                default:
                    break;
            }
        }, error => {
            toast.error("Server Error. Call to L.Adamek", SETTINGS_TOASTER);
        });

    }

    render() {
        if (sessionStorage.getItem("token") === null) {
            return <Redirect to="/login"/>
        }
        return (
            <div className='logout-wrapper'>
                <Tooltip title='Logout'>
                    <IconButton onClick={this.handleLogout}>
                        <img src={logoutIcon} alt='logout icon' className='logout-icon'/>
                    </IconButton>
                </Tooltip>
                <ToastContainer position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnVisibilityChange
                                draggable
                                pauseOnHover={false}/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(null, mapDispatchToProps)(LogoutView)
