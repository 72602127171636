import {
	LOGIN,
	LOGOUT,
	SESSION_EXPIRED,
} from "../actions/actionTypes";

const INITIAL_STATE = {
	loggedIn: sessionStorage.getItem("token") ? true : false,
};

export default function authReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
	case LOGIN:
		return {
			...state,
			loggedIn: true,
		};

	case LOGOUT:
	case SESSION_EXPIRED:
		return {
			...state,
			loggedIn: false,
		};

	default:
		return state;
	}
}
