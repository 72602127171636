import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DetailsModal from './DetailsModal';
import {injectIntl} from "react-intl";
import {compose} from "recompose";
import { useStatsTable } from '../../hooks/patient/useStatsTable';


  function StatsTable(props) {
    const {
      getTemperatureById,
      handleOpenModal,
      handleCloseModal,
      selectedAnimal,
      isModalOpen,
      messages,
      formatMessage,
      classes
    } = useStatsTable(props);
    
  
    return (
        <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow className={classes.firstRow}>
              <TableCell className={classes.tableHeaderCell}>{formatMessage(messages.animal_id)}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">{formatMessage(messages.total_attempts)}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">{formatMessage(messages.ambient_temperature)}</TableCell>
              <TableCell className={classes.tableHeaderCell} align="center">{formatMessage(messages.details)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {props.feedingData.map((row, index) => (
        <TableRow key={index} className={classes.tableRow}>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {row.animal}
          </TableCell>
          <TableCell align="center" className={classes.tableCell}>{row.attempts}</TableCell>
          <TableCell align="center" className={classes.tableCell}>
                {getTemperatureById(row.animal)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
            <IconButton onClick={() => handleOpenModal(row.animal)}>
            <KeyboardArrowDownIcon className={classes.checkIcon} />
        </IconButton>
      </TableCell>
        </TableRow>
      ))}
        </TableBody>
        </Table>
      </TableContainer>
      <DetailsModal
      isOpen={isModalOpen}
      animal={selectedAnimal}
      handleClose={handleCloseModal}
      setProgressBar={props.setProgressBar}
      year={props.year}
      month={props.month}
    />
    </>
    );
  }

  export default compose(
    injectIntl,
)(StatsTable);
