import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import Loader from "../loader/Loader";
import MonthYearPicker from "./MonthYearPicker";
import StatsTable from './StatsTable';
import ChartFeeding from "./ChartFeeding";
import {injectIntl} from "react-intl";
import {compose} from "recompose";
import { usePatient } from "../../hooks/patient/usePatient";

function Patient(props) {
    const {
        progressBar,
        setProgressBar,
        data,
        year,
        month,
        downloadData,
        isLoading,
        feedingTime,
        tempData,
        meanTemperature,
        totalAttempts,
        messages,
        classes,
        formatMessage,
    } = usePatient(props)

    return (
        <>
            <div className={classes.formRow}>
                <MonthYearPicker onDateChange={(year, month) => downloadData(year, month)} year={year} month={month} intl={props.intl} />
            </div>
            {!isLoading ?  
                data && data.length > 0 ? (
                    <>
                    <div>
                        <StatsTable feedingData={data} setProgressBar={setProgressBar} year={year} month={month} tempData={tempData}  /> 
                    </div>
                    <Paper elevation={3} style={{ padding: '20px', margin: '20px', minHeight: '400px' }} className={classes.paper}>
                    <Box className={classes.flexContainer}>
                    {/* Text section */}
                    <Box className={classes.textSection}>
                        <Typography variant="h5" className={classes.headingTypography}>{formatMessage(messages.feeding_time)}</Typography>
                        <Typography variant="body1" className={classes.statTypography}>{feedingTime&&`${parseInt(feedingTime / 60)}m ${parseInt(60*((feedingTime / 60)%1))}s`}</Typography>
            
                        <Typography variant="h5" className={classes.headingTypography}>{formatMessage(messages.ambient_temperature)}</Typography>
                        <Typography variant="body1" className={classes.statTypography}>{meanTemperature}°C</Typography>
            
                        <Typography variant="h5" className={classes.headingTypography}>{formatMessage(messages.total_attempts)}</Typography>
                        <Typography variant="body1" className={classes.statTypography}>{totalAttempts}</Typography>
                    </Box>
                    <Box  height={450} minWidth={800}>
                            <ChartFeeding year={year} month={month} setProgressBar={setProgressBar} />
                    </Box>
                    </Box>
                </Paper>
                </>
            ) : (
                <Paper elevation={3} className={classes.noDataPaper}>
                    <Typography variant="h6" className={classes.noDataText}>
                    {formatMessage(messages.no_data)}
                    </Typography>
                </Paper>
            ) : <></> }
            <Loader visible={progressBar}/>
        
        </>
    );
}

export default compose(
    injectIntl,
)(Patient);

