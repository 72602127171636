const getWeekNumber = (date) => {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNo;
};

const getFirstWeekOfMonth = (year, month) => {
    return getWeekNumber(new Date(year, month, 1));
};

const getLastWeekOfMonth = (year, month) => {
  const lastDayOfMonth = new Date(year, month + 1, 0);
  return getWeekNumber(lastDayOfMonth);
};

const getStartAndEndDateOfWeek = (weekNum, year) => {
    const jan1 = new Date(year, 0, 1); 
    const days = (weekNum - 1) * 7;

    const startDate = new Date(jan1.setDate(jan1.getDate() + days));
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1); 

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); 

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedStartDate = startDate.toLocaleDateString('en-GB', options);
    const formattedEndDate = endDate.toLocaleDateString('en-GB', options);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const getLte = (dateOne, dateTwo) => {
    const date = dateOne > dateTwo ? dateOne : dateTwo;
    return date.slice(0, 19);
};

const getGte = (dateOne, dateTwo) => {
    const date = dateOne < dateTwo ? dateOne : dateTwo;
    return date.slice(0, 19);
};

const calculateMeanAmbientTemp = (dataArray) => {
    const total = dataArray.reduce((acc, item) => acc + item.avg_ambient_temp, 0);
    const mean = total / dataArray.length;
    return parseFloat(mean.toFixed(2));
}

  export { getWeekNumber, getFirstWeekOfMonth, getLastWeekOfMonth, getStartAndEndDateOfWeek, getGte, getLte, calculateMeanAmbientTemp };