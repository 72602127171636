import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {FormattedMessage} from "react-intl";

export default function ConfirmInterventionDialog(props) {
	const {
		open,
		onClose,
		onConfirm,
	} = props;


	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="intervention-dialog-title"
			aria-describedby="intervention-dialog-description"
		>
			<DialogTitle id="intervention-dialog-title"><FormattedMessage id='confirm_intervention.title' /></DialogTitle>
			<DialogContent>
				<DialogContentText id="intervention-dialog-description">
					<FormattedMessage id='confirm_intervention.text' />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					<FormattedMessage id='confirm_intervention.cancel' />
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					<FormattedMessage id='confirm_intervention.confirm' />
				</Button>
			</DialogActions>
		</Dialog>
	);
}
